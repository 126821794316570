import { Box, Button, Typography } from "@mui/material";
import CustomDatePicker from "../../components/form/DatePicker";
import UserIcon from "../../assets/IconComponents/UserIcon";
import { useState } from "react";

const RequestedReport = () => {
  const [selectedButton, setSelectedButton] = useState("Data");

  const buttons = [
    { label: "Data", defaultBg: "#ffffff", selectedBg: "#F1F4FF" },
    { label: "Analytics", defaultBg: "#ffffff", selectedBg: "#F1F4FF" },
  ];
  return (
    <>
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        sx={{ width: "80%", display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          <Typography
            sx={{ color: "#374151", fontSize: "18px", fontWeight: "700" }}
          >
            Requested Reports Data
          </Typography>
          <Typography
            sx={{ color: "#6B7280", fontSize: "14px", fontWeight: "400" }}
          >
            Capture all customer related requested reports data here.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box mr={2}>
            <CustomDatePicker />
          </Box>

          <Box
            sx={{
              border: "1px solid rgba(213, 225, 249, 1)",
              borderRadius: "8px",
              padding: "10px",
            }}
          >
            {buttons.map(({ label, defaultBg, selectedBg }) => (
              <Button
                key={label}
                startIcon={<UserIcon />}
                onClick={() => setSelectedButton(label)}
                sx={{
                  background: selectedButton === label ? selectedBg : defaultBg,
                  height: "30px",
                  padding: "10px",
                  borderRadius: "8px",
                  color: selectedButton === label ? "#264D8E" : "#001F3D",
                  fontSize: "14px",
                  fontWeight: selectedButton === label ? "600" : "400",
                  width: label === "Data" ? "88px" : "130px",
                }}
              >
                {label}
              </Button>
            ))}
          </Box>
          
        </Box>
        
      </Box>
      
    </Box>
    <Box>
    </Box>
    </>
  );
};

export default RequestedReport;
