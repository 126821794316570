import React, { Fragment, useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TableSortLabel,
  Skeleton,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const CustomCheckbox = styled(Checkbox)({
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
});

interface Column {
  label: string;
  id: string;
  sortable?: boolean;
  render?: (row: any) => React.ReactNode;
  fontWeight?: string | number;
  textDecoration?: string;
}

interface DataTableProps {
  columns: Column[];
  rows?: any[];
  isLoading?: boolean;
  orderBy?: string;
  order?: "asc" | "desc";
  createSortHandler: (columnId: string) => void;
  checkboxSelection?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  rows = [],
  isLoading,
  orderBy,
  order,
  createSortHandler,
  checkboxSelection = false,
}) => {
  const [allSelected, setAllSelected] = useState(false);
  const [rowSelections, setRowSelections] = useState(rows.map(() => false));

  const handleSelectAll = () => {
    const newState = !allSelected;
    setAllSelected(newState);
    setRowSelections(rows.map(() => newState));
  };
 
  const handleRowSelect = (index: number) => {
    const updatedSelections = [...rowSelections];
    updatedSelections[index] = !updatedSelections[index];
    setRowSelections(updatedSelections);
    setAllSelected(updatedSelections.filter(Boolean).length === rows.length);
  };

  return (
    <TableContainer
      component={Box}
      sx={{
        borderBottom: "1px solid #D5E1F980",
        paddingX: "5px",
      }}
    >
      <Table
        stickyHeader
        sx={{ borderSpacing: "0 8px", borderCollapse: "separate" }}
      >
        <TableHead>
          <TableRow>
            {checkboxSelection && (
              <TableCell
                padding="checkbox"
                sx={{
                  border: "none !important",
                  borderTop: "1px solid #D5E1F950 !important",
                  borderBottom: "1px solid #D5E1F950 !important",
                  background: "#FCFCFD",
                }}
              >
                <CustomCheckbox
                  disableRipple
                  checked={allSelected}
                  onChange={handleSelectAll}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "#d3d3d3",
                    "&.Mui-checked": {
                      color: "#1F3A91",
                    },
                  }}
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  borderTop: "1px solid #D5E1F950 !important",
                  borderBottom: "1px solid #D5E1F950 !important",
                  borderLeft: "none !important",
                  borderRight: "none !important",
                  fontSize: "15px",
                  color: "#6B7280",
                  fontWeight: "400",
                  background: "#FCFCFD",
                  whiteSpace: "nowrap",
                  padding: {
                    xs: "15px 20px !important",
                    md: "1rem 1.5rem !important",
                  },
                }}
              >
                {column.sortable === false ? (
                  <TableSortLabel>{column.label}</TableSortLabel>
                ) : (
                  <TableSortLabel
                    active={column?.id === orderBy}
                    direction={order?.toLowerCase() as "asc" | "desc"}
                    onClick={() => createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableSkeletonComponent rows={10} columns={columns.length} />
          ) : (
            rows.map((row, index) => (
              <TableRow
                key={`table_row_${index}`}
                hover={false}
                sx={{
                  borderRadius: rowSelections[index] ? "50px" : "0",
                  boxShadow: rowSelections[index]
                    ? " 0px -6px 14.7px 0px rgba(0, 0, 0, 0.04), 0px 6px 14.7px 0px rgba(0, 0, 0, 0.04)"
                    : "none",
                  marginY: rowSelections[index] ? "10px" : "0",
                  overflow: "hidden",
                  // borderBottom: rowSelections[index] ? "10px solid transparent" : "none",
                }}
              >
                {checkboxSelection && (
                  <TableCell
                    padding="checkbox"
                    sx={{ border: "none !important" }}
                  >
                    <CustomCheckbox
                      disableRipple
                      checked={!!rowSelections[index]}
                      onChange={() => handleRowSelect(index)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        color: "#d3d3d3",
                        borderRadius: "8px",
                        "&.Mui-checked": {
                          color: "#1F3A91",
                        },
                      }}
                    />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={`${column.id}_${index}`}
                    sx={{
                      width: "max-content",
                      border: "none !important",
                      fontSize: {
                        xs: "15px !important",
                        md: "1rem !important",
                      },
                      fontWeight: column.fontWeight || "400",
                      color: "#374151",
                      whiteSpace: "nowrap",
                      padding: {
                        xs: "2px 20px !important",
                        md: "0.1rem 1.5rem !important",
                      },
                      textDecoration: column.textDecoration || "",
                    }}
                  >
                    {column.render ? column.render(row) : row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface PaginationControlProps {
  page: number;
  rowsPerPage: number;
  totalItems: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = ({
  page,
  rowsPerPage,
  totalItems,
  handlePageChange,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        "@media (max-width: 435px)": {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      }}
    >
      <Typography variant="body2" sx={{ color: "#6B7280", fontSize: "14px" }}>
        Showing{" "}
        <Typography
          component={"span"}
          sx={{ color: "#374151", fontSize: "14px", fontWeight: "600" }}
        >
          {(page - 1) * rowsPerPage + 1} -{" "}
          {Math.min(page * rowsPerPage, totalItems)}
        </Typography>{" "}
        of{" "}
        <Typography
          component={"span"}
          sx={{ color: "#374151", fontSize: "14px", fontWeight: "600" }}
        >
          {totalItems}
        </Typography>
      </Typography>

      <Box
        sx={{
          display: "flex",
          border: "1px solid #D5E1F9",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Pagination
          count={Math.ceil(totalItems / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
          siblingCount={0}
          boundaryCount={1}
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "14px",
              color: "#6B7280",
              fontWeight: "500",
              width: "100%",
              height: "33px",
              borderRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderLeft: "1px solid #D5E1F9",
              margin: "0",
            },
            "& .Mui-selected": {
              backgroundColor: "#F1F4FF !important",
              color: "#1F3A91",
            },
            "& .MuiPaginationItem-icon": {
              color: "#6B7280",
            },
          }}
        />
      </Box>
    </Box>
  );
};

const TableSkeletonComponent = ({ columns, rows }: any): React.ReactElement => {
  const tableRow = useMemo(() => {
    const rowsElements: any[] = [];
    for (let row = 0; row < rows; row++) {
      const rowElements = [];
      for (let column = 0; column < columns; column++) {
        rowElements.push(
          <TableCell>
            <Skeleton />
          </TableCell>
        );
      }
      rowsElements.push(<TableRow>{rowElements}</TableRow>);
    }
    return rowsElements;
  }, [columns, rows]);

  return <Fragment>{tableRow}</Fragment>;
};

export { DataTable, PaginationControl, TableSkeletonComponent };
