const NotificationsActiveIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="6" fill="#151314"/>
    <path d="M12.3167 9.19167L11.125 8C9.12498 9.525 7.80832 11.875 7.69165 14.5417H9.35832C9.48332 12.3333 10.6167 10.4 12.3167 9.19167ZM22.6416 14.5417H24.3083C24.1833 11.875 22.8667 9.525 20.875 8L19.6917 9.19167C21.375 10.4 22.5166 12.3333 22.6416 14.5417ZM21 14.9583C21 12.4 19.6333 10.2583 17.25 9.69167V9.125C17.25 8.43333 16.6917 7.875 16 7.875C15.3083 7.875 14.75 8.43333 14.75 9.125V9.69167C12.3583 10.2583 11 12.3917 11 14.9583V19.125L9.33332 20.7917V21.625H22.6666V20.7917L21 19.125V14.9583ZM16 24.125C16.1167 24.125 16.225 24.1167 16.3333 24.0917C16.875 23.975 17.3167 23.6083 17.5333 23.1083C17.6167 22.9083 17.6583 22.6917 17.6583 22.4583H14.325C14.3333 23.375 15.075 24.125 16 24.125Z" fill="white"/>
    <circle cx="21" cy="9.5" r="3" fill="#FD4A38" stroke="#1D232C" stroke-width="2"/>
    </svg>
    
  )
}

export default NotificationsActiveIcon