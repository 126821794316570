import { RouteObject } from 'react-router-dom';
import DashboardPage from '../pages/dashboard';
import IBAReportPage from '../pages/ibaReport';
import CustomersPage from '../pages/customers';
import PaymentProcessingPage from '../pages/paymentProcessing';
import InvoiceRecordsPage from '../pages/invoiceRecords';
import UsersPage from '../pages/users';
import ActivityLogsPage from '../pages/activityLogs';
import ConnectPage from '../pages/connect';
import SettingsPage from '../pages/settings';
import IBAReportAnalysis from '../pages/ibaReport/ibaAnalysis';
import IBARequest from '../pages/ibaReport/IBARequest';
import AddNewCustomerPage from '../pages/customers/AddNewCustomer';
import Page from '../components/@extended/Page';
import InvoiceReport from '../pages/invoiceRecords/InvoiceReport';
import EditCustomer from '../pages/customers/EditCustomer';

const MainRouter: RouteObject[] = [
    {
        path: '',
        element: <Page component={<DashboardPage />} title='Dashboard' />,
    },
    {
        path: 'report',
        element: <Page component={<IBAReportPage />} title='IBA Report' />,
    },
    {
        path: 'report/request',
        element: <Page component={<IBARequest />} title='IBA Report Request' />,
    },
    {
        path: 'report/analysis',
        element: <Page component={<IBAReportAnalysis />} title='IBA Report Analysis' />,
    },
    {
        path: 'customers',
        element: <Page component={<CustomersPage />} title='Customer’s Listing' />,
    },
    {
        path: 'customers/new',
        element: <Page component={<AddNewCustomerPage />} title='Create New Customer' />,
    },
    {
        path: 'customers/:customerId/edit',
        element: <Page component={<EditCustomer />} title='Edit Customer' />,
    },
    {
        path: 'customers/:customerId/requested-report',
        element: <Page component={<EditCustomer />} title='Edit Customer' />,
    },
    {
        path: 'payment-processing',
        element: <Page component={<PaymentProcessingPage />} title='Payment Processing' />,
    },
    {
        path: 'invoice',
        element: <Page component={<InvoiceRecordsPage />} title='Invoice Records' />,
    },
    {
        path: 'invoice/report',
        element: <Page component={<InvoiceReport />} title='Invoice Records' />,
    },
    {
        path: 'users',
        element: <Page component={<UsersPage />} title='Users' />,
    },
    {
        path: 'activity-logs',
        element: <Page component={<ActivityLogsPage />} title='Activity Logs' />,
    },
    {
        path: 'connect',
        element: <Page component={<ConnectPage />} title='Connect' />,
    },
    {
        path: 'settings',
        element: <Page component={<SettingsPage />} title='Settings' />,
    },
];

export default MainRouter;
