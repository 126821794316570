import { RouteObject } from 'react-router-dom';
import ForgotPasswordPage from '../pages/auth/Forgot-password';
import LoginPage from '../pages/auth/Login';
import RegiserPage from '../pages/auth/Register';
import ResetPasswordPage from '../pages/auth/reset-password';
import Page from '../components/@extended/Page';

const AuthRouter: RouteObject[] = [
    {
        path: 'login',
        element: <Page component={<LoginPage />} title='Login' />
    },
    {
        path: 'register',
        element: <Page component={<RegiserPage />} title='User Register' />
    },
    // As Per Client Requirement we don't need reset password for now.
    // {
    //     path: 'forgot-password',
    //     element: <Page component={<ForgotPasswordPage />} title='Forgot Password' />
    // },
    // {
    //     path: 'reset-password',
    //     element: <Page component={<ResetPasswordPage />} title='Reset Password' />
    // },
];

export default AuthRouter;
