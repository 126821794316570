import { Checkbox, Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { useState } from 'react';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link as RouterLink } from "react-router-dom";


import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReplayIcon from "@mui/icons-material/Replay";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";


const reportsList: any[] = [
    { id: 10001, name: 'Customer Name 10001', phone: '923-234-10001', score: '801', ordered: '25/05/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10002, name: 'Customer Name 10002', phone: '923-234-10002', score: '802', ordered: '24/06/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Pending' },
    { id: 10003, name: 'Customer Name 10003', phone: '923-234-10003', score: '803', ordered: '25/05/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Pending' },
    { id: 10004, name: 'Customer Name 10004', phone: '923-234-10004', score: '-', ordered: '21/08/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10005, name: 'Customer Name 10005', phone: '923-234-10005', score: '805', ordered: '15/04/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10006, name: 'Customer Name 10006', phone: '923-234-10006', score: '806', ordered: '02/05/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Pending' },
    { id: 10007, name: 'Customer Name 10007', phone: '923-234-10007', score: '807', ordered: '22/07/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Expired' },
    { id: 10008, name: 'Customer Name 10008', phone: '923-234-10008', score: '-', ordered: '23/10/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Pending' },
    { id: 10009, name: 'Customer Name 10009', phone: '923-234-10009', score: '809', ordered: '01/11/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10010, name: 'Customer Name 10010', phone: '923-234-10010', score: '810', ordered: '03/11/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Pending' },
    { id: 10011, name: 'Customer Name 10011', phone: '923-234-10011', score: '811', ordered: '07/10/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10012, name: 'Customer Name 10012', phone: '923-234-10012', score: '812', ordered: '05/01/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Pending' },
    { id: 10014, name: 'Customer Name 10014', phone: '923-234-10014', score: '-', ordered: '10/02/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10015, name: 'Customer Name 10015', phone: '923-234-10015', score: '-', ordered: '11/06/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Expired' },
    { id: 10016, name: 'Customer Name 10016', phone: '923-234-10016', score: '816', ordered: '15/04/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10017, name: 'Customer Name 10017', phone: '923-234-10017', score: '817', ordered: '17/02/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
    { id: 10018, name: 'Customer Name 10018', phone: '923-234-10018', score: '818', ordered: '20/05/2024', requestedBy: 'Admin', sendVia: 'Email', status: 'Completed' },
];

export default function InstanceBankAnalysisReport(props: any): any {

    const [orderBy, setOrderBy] = useState<string>('id');

    const createSortHandler = (column: string) => { };


    const actionRender = (status: string) => {
        switch (status) {
            case 'Completed':
                return <RouterLink to='/report/analysis'>
                    <IconButton className='primary'>
                        <RemoveRedEyeIcon className='primary' />
                    </IconButton>
                </RouterLink>
                break;
            case 'Expired':
                return <IconButton className='primary'>
                    <ReplayIcon className='primary' />
                </IconButton>
                break;
            case 'Pending':
                return <IconButton className='primary'>
                    <NotificationsActiveIcon className='primary' />
                </IconButton>
                break;
            default:
                return null;
                break;
        }
    }


    const renderStatus = (status: string) => {
        switch (status) {
            case "Completed":
                return (
                    <Chip
                        icon={
                            <FiberManualRecordIcon
                                sx={{ color: "green !important", height: "10px" }}
                            />
                        }
                        label={status}
                        sx={{ backgroundColor: "#edf6ee", color: "green", width: "130px" }}
                    />
                );
                break;
            case "Expired":
                return (
                    <Chip
                        icon={
                            <FiberManualRecordIcon
                                sx={{ color: "red !important", height: "10px" }}
                            />
                        }
                        label={status}
                        sx={{ backgroundColor: "#fff2f0", color: "red", width: "130px" }}
                    />
                );
                break;
            case "Pending":
                return (
                    <Chip
                        icon={
                            <FiberManualRecordIcon
                                sx={{ color: "blue !important", height: "10px" }}
                            />
                        }
                        label={status}
                        sx={{ backgroundColor: "#ecf7ff", color: "blue", width: "130px" }}
                    />
                );
                break;
            default:
                return null;
                break;
        }
    };
    return <TableContainer>
        <Table>

            <TableHead sx={{ backgroundColor: "#eef7ff" }}>
                <TableRow sx={{ backgroundColor: "#fcfcfd" }}>
                    <TableCell padding="checkbox" sx={{ border: 'none' }}>
                        <Checkbox
                            color="primary"
                            inputProps={{
                                "aria-label": "select all desserts",
                            }}
                        />
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "id"}
                            direction={orderBy === "id" ? "asc" : "desc"}
                            onClick={(e) => createSortHandler("id")}
                        >
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "name"}
                            direction={orderBy === "name" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("name")}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "phone"}
                            direction={orderBy === "phone" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("phone")}
                        >
                            Phone
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "score"}
                            direction={orderBy === "score" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("score")}
                        >
                            Score
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "ordered"}
                            direction={orderBy === "ordered" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("ordered")}
                        >
                            Ordered On
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "requestedBy"}
                            direction={orderBy === "requestedBy" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("requestedBy")}
                        >
                            Requested By
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "sendVia"}
                            direction={orderBy === "sendVia" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("sendVia")}
                        >
                            Sent Via
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        <TableSortLabel
                            active={orderBy === "status"}
                            direction={orderBy === "status" ? "desc" : "asc"}
                            onClick={(e) => createSortHandler("status")}
                        >
                            Status
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: 'none' }}>
                        Action
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {reportsList.map((element, index: number) => (
                    <TableRow key={`table_row_${index}`} hover={true}>
                        <TableCell padding="checkbox" sx={{ border: 'none' }}>
                            <Checkbox
                                color="primary"
                                inputProps={{
                                    "aria-label": "select all desserts",
                                }}
                            />
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.id}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.name}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.phone}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.score}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.ordered}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.requestedBy}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {element.sendVia}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            <Typography>
                                {renderStatus(element.status)}
                            </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                            {actionRender(element.status)}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>

        </Table>

    </TableContainer>
}