import React, { useContext, useState } from 'react';
import {
    Box, Button, Typography, Paper, Grid2 as Grid,
    OutlinedInput, FormControl, InputAdornment,
    IconButton, FormHelperText,
    CircularProgress,
    useTheme
} from '@mui/material';
import { styled, useMediaQuery } from '@mui/system';

import { ReactComponent as LoginSVG } from '../../assets/media/svg/Login_Image.svg';

import { Login } from '../../api/AuthAPI';

import { ReactComponent as BankSureLogo } from '../../assets/media/svg/banksure-logo.svg';


import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';

import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import LoginFormValidator from '../../utils/validations/loginFormValidatior';
import { AuthContext } from '../../context/AuthContext';

import { SnackbarCloseReason } from '@mui/material/Snackbar';

import { AppContext } from '../../context/AppContext';
import { useAlertMessage } from '../../utils/useAlertMessage';
import { AxiosError } from 'axios';


// Styled component for the layout
const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#eff4f9', // Set background color here
}));

const ImageSection = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.grey[200],
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    background: `url(/bg.svg) center center`,
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensures the image covers the area without distortion
    },
}));

const FormSection = styled(Box)(({ theme }) => ({
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    // backgroundColor: theme.palette.background.default,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    // marginBottom: theme.spacing(2),
    // display: 'flex',
    // display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // width: '100%',
    justifyContent: 'center',
    '&': {

    }
}));


/**
 * 
 * @param token - a JWT token
 * @returns 
 */
function parseJwt(token: string) {
    try {

        var jsonPayload = decodeURIComponent(window.atob(
            token
                .split('.')[1]
                .replace(/-/g, '+')
                .replace(/_/g, '/'))
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

        return JSON.parse(jsonPayload);
    } catch (err) {
        console.error('Error: ', err);
        return {};
    }
}

const LoginPage = () => {

    const { user, setUser } = useContext(AuthContext);
    const [showPassword, togglePassword] = useState(false);
    const [isSubmitted, toggleSubmit] = useState(false);

    const { app: appCtx, updateCtx } = useContext(AppContext);

    const navigate = useNavigate();

    const notification = useAlertMessage();

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: LoginFormValidator,
        onSubmit: async (values) => {

            toggleSubmit(true);
            try {
                if (isSubmitted) {
                    console.info('Login is in progress');
                    return;
                }

                const result: any = await Login(values.username, values.password);


                // const res = Cookies.get('access_token');

                // enqueueSnackbar('Login Successful');

                // if (result?.token) {
                //     const decodedToken = parseJwt(result.token);
                //     // setUser(decodedToken);
                //     localStorage.setItem('user', JSON.stringify(decodedToken))
                // setNotification({ message: 'Login Successful', variant: '' });

                toggleSubmit(false);
                if (result) {
                    updateCtx({
                        ...appCtx,
                        user: {
                            firstName: 'Demo',
                            lastName: 'User'
                        },
                        isLoggedIn: true
                    });
                    // notification.successNotification('Success', 'Logged in successfully.');
                    navigate('/');
                } else {
                    // enqueueSnackbar('Something went wrong');
                    alert('Something went wrong');
                }

                // } else {
                //     setNotification({
                //         message: 'Error while login to the applciation',
                //         variant: 'error'
                //     });
                // }
            } catch (err: AxiosError | any) {
                toggleSubmit(false);
                console.error(err);
                if (err.status >= 400 && err.status < 500) {
                    notification.errorNotification('Error', 'Incorrect email or password.');
                } else {
                    notification.errorNotification('Error', 'Something went wrong!');
                }
                // setNotification({
                //     message: 'Invalid Username/Password',
                //     variant: 'error'
                // });
            }
        }
    });

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container sx={{ height: '100vh' }}>
                <Grid size={{ xl: 5, lg: 5, md: 0, xs: 0, sm: 0 }} sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'none',
                        lg: 'block',
                        xl: 'block'
                    }
                }}>
                    <ImageSection>
                        <Grid>
                            <Grid size={12}>
                                <LoginSVG />
                            </Grid>
                            <Grid size={12}>
                                <Typography variant='h5' color='white' align='center'>
                                    Get Started with BankSure today!
                                </Typography>
                            </Grid>
                        </Grid>
                    </ImageSection>
                </Grid>
                <Grid size={{ xl: 7, lg: 7, md: 12, xs: 12, sm: 12 }} sx={{ height: '100%' }}>
                    <Box sx={{
                        overflowY: 'auto',
                        marginTop: 'auto'
                    }}>
                        <FormSection>
                            <div>
                                <Link to={'https://banksure.ai/'} target='_blank'>
                                    <IconWrapper style={{ maxHeight: 100 }}>
                                        {/* <img src={bankSureLogo} alt='BankSure Logo' /> */}
                                        <BankSureLogo width='200px' />
                                        {/* <Icon sx={{ width: '100%' }}>
                                            <CustomSvgIcon sx={{ width: '100%' }} />
                                        </Icon> */}
                                    </IconWrapper>
                                </Link>
                            </div>
                            <br /> <br />
                            <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px', width: '100%', maxWidth: isSmallScreen ? '90%' : '60%' }}>
                                <Typography variant="h4" gutterBottom align='center'>
                                    <b>
                                        Welcome back!
                                    </b>
                                </Typography>
                                <br />
                                <br />
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2} rowSpacing={4}>
                                        <Grid size={12}>
                                            <Typography>
                                                Email Address
                                            </Typography>

                                            <FormControl variant="standard" fullWidth>
                                                <OutlinedInput
                                                    name="username"
                                                    placeholder="Enter your business email id / username"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <EmailOutlined />
                                                        </InputAdornment>
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.username}
                                                    error={!formik.isSubmitting && !!formik.errors.username}
                                                />
                                                {formik.errors.username ?
                                                    <FormHelperText error={!!formik.errors.username}>
                                                        {formik.errors.username}
                                                    </FormHelperText> : ''
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid size={12}>
                                            <Typography>
                                                Password
                                            </Typography>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel htmlFor="input-with-icon-adornment">
                                                        Email Address
                                                    </InputLabel> */}
                                                <OutlinedInput
                                                    name="password"
                                                    placeholder="Enter password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <LockOpenIcon />
                                                        </InputAdornment>
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => togglePassword(!showPassword)}>
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                    error={!formik.isSubmitting && !!formik.errors.password}
                                                />
                                                {formik.errors.password ?
                                                    <FormHelperText error={!!formik.errors.password}>
                                                        {formik.errors.password}
                                                    </FormHelperText> : ''
                                                }
                                            </FormControl>
                                        </Grid>

                                        {/* As Per Client Requirement we don't need reset password for now.
                                    <Grid size={12} sx={{}}>
                                        <Typography align='right'>
                                            <Link to="/auth/forgot-password">
                                                Forgot Password?
                                            </Link>
                                        </Typography>
                                    </Grid> */}

                                        <Grid size={12}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                style={{ marginTop: '16px', backgroundColor: '#264D8E', height: 50 }}
                                            >
                                                {isSubmitted ? <CircularProgress size={20} sx={{ height: '20px', width: '20px', color: 'white' }} /> : 'Sign In'}
                                            </Button>
                                        </Grid>

                                        <Grid size={12}>
                                            <Typography align='center'>
                                                Need an account?
                                                <b>
                                                    <Link to={'/auth/register'}>
                                                        Sign up with email
                                                    </Link>
                                                </b>
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Paper>
                        </FormSection>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
}

export default LoginPage;
