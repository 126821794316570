import React from 'react'

const TransferIcon = () => {
  return (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.683594" width="21" height="21" rx="10.5" fill="#1D3DB0"/>
      <path d="M10.3125 8.76123C9.7665 8.91056 9.4375 9.32998 9.4375 9.72548C9.4375 10.121 9.7665 10.5404 10.3125 10.6891V8.76123ZM11.1875 11.6785V13.6058C11.7335 13.4571 12.0625 13.0376 12.0625 12.6421C12.0625 12.2466 11.7335 11.8272 11.1875 11.6785Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5832 11.1834C16.5832 14.4052 13.9716 17.0168 10.7498 17.0168C7.52809 17.0168 4.9165 14.4052 4.9165 11.1834C4.9165 7.96168 7.52809 5.3501 10.7498 5.3501C13.9716 5.3501 16.5832 7.96168 16.5832 11.1834ZM10.7498 7.24593C10.8659 7.24593 10.9771 7.29202 11.0592 7.37407C11.1412 7.45612 11.1873 7.5674 11.1873 7.68343V7.86835C12.1382 8.03868 12.9373 8.75326 12.9373 9.7251C12.9373 9.84113 12.8912 9.95241 12.8092 10.0345C12.7271 10.1165 12.6159 10.1626 12.4998 10.1626C12.3838 10.1626 12.2725 10.1165 12.1905 10.0345C12.1084 9.95241 12.0623 9.84113 12.0623 9.7251C12.0623 9.3296 11.7333 8.91018 11.1873 8.76085V10.785C12.1382 10.9553 12.9373 11.6699 12.9373 12.6418C12.9373 13.6136 12.1382 14.3282 11.1873 14.4985V14.6834C11.1873 14.7995 11.1412 14.9107 11.0592 14.9928C10.9771 15.0748 10.8659 15.1209 10.7498 15.1209C10.6338 15.1209 10.5225 15.0748 10.4405 14.9928C10.3584 14.9107 10.3123 14.7995 10.3123 14.6834V14.4985C9.3615 14.3282 8.56234 13.6136 8.56234 12.6418C8.56234 12.5257 8.60843 12.4145 8.69048 12.3324C8.77253 12.2504 8.8838 12.2043 8.99984 12.2043C9.11587 12.2043 9.22715 12.2504 9.3092 12.3324C9.39124 12.4145 9.43734 12.5257 9.43734 12.6418C9.43734 13.0373 9.76634 13.4567 10.3123 13.6054V11.5818C9.3615 11.4115 8.56234 10.6969 8.56234 9.7251C8.56234 8.75326 9.3615 8.03868 10.3123 7.86835V7.68343C10.3123 7.5674 10.3584 7.45612 10.4405 7.37407C10.5225 7.29202 10.6338 7.24593 10.7498 7.24593Z" fill="white"/>
      </svg>
  )
}

export default TransferIcon