import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import CheckIcon from "../../assets/IconComponents/CheckIcon";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgb(1, 182, 168)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgb(1, 182, 168)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#245FECE5',
    borderWidth: "0 0 0 2px",
    borderStyle: "dashed",
    margin: "-12px 0 -12px -3.5px",
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: ownerState.active || ownerState.completed ? 'rgb(1, 182, 168)' : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .CustomStepIcon-completedIcon': {
    color: 'rgb(1, 182, 168)',
    zIndex: 1,
    fontSize: 18,
  },
  '& .CustomStepIcon-circle': {
    color: "#245FECE5",
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: ownerState.completed ? 'currentColor' : 'transparent', 
    border: ownerState.completed ? 'none' : '4px solid currentColor', 
  },
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <CheckIcon className="CustomStepIcon-completedIcon" />
      ) : (
        <div className="CustomStepIcon-circle" />
      )}
    </CustomStepIconRoot>
  );
}


const CustomerOverview = ({ section, details, src, sectionDetails }) => {
  const isDisabled = details.length > 0;

  return (
    <Box
      sx={{
        padding: "10px",
        pb: 0,
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        mb: "20px",
        border: !isDisabled ? "1.5px solid #DBE9FE" : "1.5px solid #245FECE5",
        borderTop: !isDisabled ? "8px solid #DBE9FE" : "8px solid #245FECE5",
        pointerEvents: !isDisabled ? "none" : "auto",
        opacity: !isDisabled ? 0.6 : 1,
        ":last-child": {
          mb: "0",
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <img
          src={src}
          alt="information"
          style={{
            width: "48px",
            height: "48px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ fontWeight: "600", color: "#1A369E", fontSize: "14px" }}
          >
            {section}
          </Typography>
          <Typography
            sx={{ fontWeight: "400", color: "#6B7280", fontSize: "14px" }}
          >
            {sectionDetails}
          </Typography>
        </Box>
      </Box>

      {isDisabled && (
        <Box sx={{ position: "relative", marginLeft: "12px" }}>
          <Box
            sx={{
              width: "2px",
              height: "20px",
              // backgroundColor: "#245FECE5",
              borderLeft: "2px dashed rgb(1, 182, 168)",
              position: "absolute",
              top: "-9px",
              left: "8.5px",
              zIndex: 0,
            }}
          />

          <Stepper
            orientation="vertical"
            connector={<CustomStepConnector />}
            sx={{ padding: "0" }}
          >
            {details.map((step, index) => (
              <Step key={step.label} completed={step.value !== "-"}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Box sx={{ margin: "0 0 0 10px", width: "85%" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#374151",
                        height: "20px",
                        overflow: "hidden",
                      }}
                    >
                      {step.label}:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#6B7280",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      title={step.value}
                    >
                      {step.value.length > 25
                        ? `${step.value.substring(0, 25)}...`
                        : step.value}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </Box>
  );
};

export default CustomerOverview;
