const DollarIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="38" height="38" rx="19" fill="#EFF6FF"/>
    <path d="M21 9H13C11.9 9 11.01 9.9 11.01 11L11 27C11 28.1 11.89 29 12.99 29H25C26.1 29 27 28.1 27 27V15L21 9ZM22 19H18V20H21C21.55 20 22 20.45 22 21V24C22 24.55 21.55 25 21 25H20V26H18V25H16V23H20V22H17C16.45 22 16 21.55 16 21V18C16 17.45 16.45 17 17 17H18V16H20V17H22V19ZM20 15V10.5L24.5 15H20Z" fill="#363636"/>
    </svg>
    
  )
}

export default DollarIcon