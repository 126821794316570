import { createTheme } from '@mui/material';

const ThemeV2 = createTheme({
    palette: {
        background: {
            default: "#e4f0e2"
        }
    }
});

export default ThemeV2;
