import { Box, Container, Icon, Typography } from '@mui/material';
import { ReactComponent as BankSureLogo } from '../../assets/media/svg/banksure-logo.svg';


const PageLoader = (props: any) => {
    return <Box sx={{ height: '100vh', width: '100%', textAlign: 'center' }}>
        {/* <Icon sx={{ marginTop: '20%' }} > */}
        <Container sx={{ marginTop: '30vh' }}>
            <BankSureLogo height={200} width={200} />
        </Container>
        {/* </Icon> */}
    </Box>
}

export default PageLoader;