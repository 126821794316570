const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.17502 5.00009V4.99967C2.17502 4.35588 2.69609 3.83301 3.33335 3.83301H16.6667C17.3072 3.83301 17.8334 4.35915 17.8334 4.99967V14.9997C17.8334 15.6402 17.3072 16.1663 16.6667 16.1663H3.33335C2.69289 16.1663 2.16679 15.6403 2.16669 14.9999C2.16669 14.9998 2.16669 14.9997 2.16669 14.9997L2.17502 5.00009ZM16.9317 7.09034L17.1667 6.94346V6.66634V4.99967V4.09755L16.4017 4.57568L10 8.57672L3.59835 4.57568L2.83335 4.09755V4.99967V6.66634V6.94346L3.06835 7.09034L9.73502 11.257L10 11.4226L10.265 11.257L16.9317 7.09034Z"
        fill="#6B7280"
        stroke="#6B7280"
      />
    </svg>
  );
};

export default EmailIcon;
