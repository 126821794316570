
import DashboardIcon from '@mui/icons-material/Dashboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import Person3Icon from '@mui/icons-material/Person3';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const menus: any = [
    {
        label: 'Client Dashboard',
        icon: DashboardIcon,
        to: '/',
        breadcrump: false
    },
    {
        label: 'IBA Report',
        icon: PieChartIcon,
        to: '/report',
        breadcrump: false
    },
    {
        label: 'Customers',
        icon: Person3Icon,
        to: '/customers',
        breadcrump: false
    },
    {
        label: 'Invoice Records',
        icon: RequestQuoteIcon,
        to: '/invoice',
        breadcrump: false
    },
    {
        label: 'Users',
        icon: AssignmentIndIcon,
        to: '/users',
        breadcrump: false
    },
    {
        label: 'Activity Logs',
        icon: TextSnippetIcon,
        to: '/activity-logs',
        breadcrump: false
    },
    {
        label: 'Connect',
        icon: ChatIcon,
        to: '/connect',
        breadcrump: false
    },
    {
        label: 'Settings',
        icon: SettingsSuggestIcon,
        to: '/settings',
        breadcrump: false
    },
];


export default menus;