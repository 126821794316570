import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import Panel from "../../components/Panel";
import "./IbaReport.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import SelectComponent from "../../components/form/SelectComponent";
import UserIcon from "../../assets/IconComponents/UserIcon";
import EditModal from "../../components/form/EditModal";
import InputComponent from "../../components/form/input";
import YearPicker from "../../components/form/YearPicker";
import CustomMonthPicker from "../../components/form/MonthPicker";
import {
  DataTable,
  PaginationControl,
} from "../../components/form/TableComponent";
import TruncatedTooltipText from "../../components/form/ToolTip";
import EditIcon from "../../assets/media/icons/EditIcon.png";
import ExpiryIcon from "../../assets/media/icons/Expiry.png";
import PendingIcon from "../../assets/media/icons/Pending.png";
import ExpandMoreIcon from "../../assets/IconComponents/ExpandMoreIcon";
import FileDownloadIcon from "../../assets/IconComponents/FileDownloadIcon";
import { CreateNewIBAReqest, GetReportRequestsList } from '../../api/IBAReport';
import { useAlertMessage } from '../../utils/useAlertMessage';
import { GetCustomers } from '../../api/customerAPI';
import moment from 'moment';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  color: "#ff0000",
  border: "none",
  height: "48px !important",
  "& .MuiButtonGroup-grouped": {
    border: "1px solid #E5E7EB !important",
    // width:"max-content !important"
  },
  "&:hover": {
    boxShadow: "none !important",
  },
}));

interface OptionType {
  label: string;
  value: string | number;
}

const customers = [
  { label: "Elijah Addams", value: 1 },
  { label: "Mark Jones", value: 2 },
  { label: "James Taylor", value: 3 },
  { label: "David Brown", value: 4 },
];

const IBAReportPage = (props: any) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("id");
  const theme = useTheme();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [totalRecordsCount, setTotalRecordsCount] = useState<any>(0);
  const [customersList, setCustomersList] = useState<any[]>([]);
  const [customersCount, setCustomersCount] = useState<any>(0);
  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    status: 'All',
    orderBy: "ordered",
    order: "desc",
  });
  const [customerQuery, setCustomerQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "createdDate",
    order: "desc",
  });
  const [reportsList, setReportsList] = useState<any[]>([]);

  const notification = useAlertMessage();

  // Init Component
  useEffect(() => {
    getReportList(query, true);
    getCustomersList(customerQuery);
  }, []);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getReportList({
        ...query,
        pageNumber: 1,
      });
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [query.nameQuery, 500]);

  const getReportList = async (query: any, onInitialCall?: boolean) => {
    toggleLoading(true);
    if (!onInitialCall) {
      setQuery(query);
    }
    try {
      // const result: any = await GetReportRequestsList(query);
      // result.data = (result?.data || []).map((report: any) => ({
      //   id: report.id,
      //   name: report?.customer?.firstName + ' ' + report?.customer?.lastName,
      //   phone: report?.customer?.phoneNumber,
      //   createdDate: moment(report?.createdDate).format('DD/MM/YYYY'),
      //   score: '-',
      //   sendVia: report?.sentVia?.join(','),
      //   status: report?.status
      // }));
      // setReportsList([
      //   {
      //     id: 10001,
      //     name: "Customer Name",
      //     phone: "923-234-10001",
      //     score: "801",
      //     ordered: "25/05/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "completed",
      //   },
      //   {
      //     id: 10002,
      //     name: "Customer Name",
      //     phone: "923-234-10002",
      //     score: "802",
      //     ordered: "24/06/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "pending",
      //   },
      //   {
      //     id: 10003,
      //     name: "Customer Name",
      //     phone: "923-234-10003",
      //     score: "803",
      //     ordered: "25/05/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "pending",
      //   },
      //   {
      //     id: 10004,
      //     name: "Customer Name",
      //     phone: "923-234-10004",
      //     score: "-",
      //     ordered: "21/08/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "completed",
      //   },
      //   {
      //     id: 10005,
      //     name: "Customer Name",
      //     phone: "923-234-10005",
      //     score: "805",
      //     ordered: "15/04/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "completed",
      //   },
      //   {
      //     id: 10006,
      //     name: "Customer Name",
      //     phone: "923-234-10006",
      //     score: "806",
      //     ordered: "02/05/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "pending",
      //   },
      //   {
      //     id: 10007,
      //     name: "Customer Name",
      //     phone: "923-234-10007",
      //     score: "807",
      //     ordered: "22/07/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "expired",
      //   },
      //   {
      //     id: 10008,
      //     name: "Customer Name",
      //     phone: "923-234-10008",
      //     score: "-",
      //     ordered: "23/10/2024",
      //     requestedBy: "Admin",
      //     sendVia: "Email",
      //     status: "pending",
      //   },
      // ]);
      // setTotalRecordsCount(8);
      const result: any = await GetReportRequestsList(query);
      result.data = (result?.data || []).map((report: any) => ({
        id: report.id,
        name: report?.customer?.firstName + ' ' + report?.customer?.lastName,
        phone: report?.customer?.phoneNumber,
        ordered: moment(report?.createdDate).format('DD/MM/YYYY'),
        score: '-',
        sendVia: report?.sentVia?.join(','),
        requestedBy: 'Admin',
        status: report?.status
      }));
      setReportsList(result?.data);
      setTotalRecordsCount(result.totalCount);

      toggleLoading(false);
    } catch (err) {
      console.error("");
      notification.errorNotification(
        "Error",
        "Error in fetching customer list."
      );
      toggleLoading(false);
    }
  };

  const getCustomersList = async (query: any = {}) => {
    let customerList: any = await GetCustomers(query);
    let list = [];
    if (customerList?.data?.length) {
      list = customerList?.data?.map((customer: any) => {
        return {
          label: `${customer.firstName} ${customer.lastName}`,
          value: customer.id
        };
      }) || [];
    }
    setCustomersList(list || []);
    setCustomersCount(customerList.totalCount);
  };

  const renderStatus = (status: string) => {
    const statusConfig = {
      completed: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
      },
      expired: {
        iconColor: "#ED9902",
        backgroundColor: "#FFF5E4",
        textColor: "#ED9902",
      },
      pending: {
        iconColor: "#AF6DD1",
        backgroundColor: "rgba(168, 0, 255, 0.06)",
        textColor: "#AF6DD1",
      },
    };

    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={status}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  const createSortHandler = (key: string) => {
    // setReportsList((prevValue) => {
    //   const result = prevValue.sort((a: any, b: any): any => {
    //     return a[key] < b[key] ? -1 : 1;
    //   });
    //   return result;
    // });
    // setOrderBy(key);
    // return null;
    getReportList({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const [open, setOpen] = useState(false);
  const [verification, setVerification] = useState("SMS");
  const [selectedCustomer, setSelectedCustomer] = useState<OptionType | null>(
    null
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleVerificationChange = (event: any) => {
    setVerification(event.target.value);
  };

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    // setPage(value);
    getReportList({
      ...query,
      pageNumber: value,
    });
  };

  const handleInputChange = (event: any) => {
    setQuery({
      ...query,
      nameQuery: event.target.value,
    });
  };

  const selectReportStatus = (type: string) => {
    getReportList({
      ...query,
      pageNumber: 1,
      status: type,
    });
  };

  const CreateIBARequest = async () => {
    try {
      const request = await CreateNewIBAReqest(selectedCustomer?.value || '', verification);
      notification.successNotification(
        "Success",
        "Create report request submitted successfully!"
      );
      handleClose();
    } catch (err) {
      console.log('Error', err);
      notification.errorNotification(
        "Error",
        "Something went wrong"
      );
    }
    await getReportList(query);
  }

  return (
    <Grid container>
      <Grid container size={12}>
        <Grid
          size={{ xs: 6, md: 8 }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>
            All IBA related data can be managed from this screen
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }} textAlign={"right"}>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <AddIcon sx={{ color: theme.palette.primary.contrastText }} />
            }
            onClick={handleOpen}
            sx={{
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.light,
              padding: "10px 16px",
              borderRadius: "10px",
            }}
          >
            New IBA Request
          </Button>
        </Grid>
        <Grid size={12} container marginTop={"20px"}>
          <Panel sx={{ width: "100%" }}>
            <Box sx={{ padding: "20px 15px 10px" }}>
              <Box
                display="flex"
                justifyContent={"space-between"}
                sx={{
                  gap: { lg: 1, xl: 2 },
                  flexDirection: { xs: "column", sm: "column", lg: "row" },
                }}
              >
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  gap={2}
                  sx={{
                    width: { sm: "100%", lg: "calc(100% - 530px)" },
                    marginBottom: { sm: "10px", lg: "0px" },
                    flexDirection: { xs: "column", sm: "row", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: { xs: "100%", sm: "300px", lg: "250px", xl: "300px" },
                    }}
                  >
                    <InputComponent
                      onChange={handleInputChange}
                      placeholder="Search"
                      icon={<SearchIcon />}
                      backgroundColor="#fff"
                      borderColor="#E5E7EB"
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    gap={1}
                  >
                    <Box sx={{ maxWidth: { md: "200px", lg: "150px" } }}>
                      <CustomMonthPicker borderColor="#E5E7EB" backgroundColor="#fff" />
                    </Box>
                    <Box sx={{ maxWidth: { md: "200px", lg: "150px" } }}>
                      <YearPicker borderColor="#E5E7EB" backgroundColor="#fff" />
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" sx={{ gap: { xs: 2, lg: 1, xl: 2 }, marginTop: { xs: "15px", sm: "0px" }, flexWrap: { xs: "wrap", sm: "nowrap" } }}>
                  <Box>
                    <StyledButtonGroup>
                      {[
                        { value: 'All', label: "All" },
                        { value: "expired", label: "Expired Requests" },
                        { value: "pending", label: "Pending Requests" },
                      ].map((type) => (
                        <Button
                          key={type.label}
                          onClick={() => selectReportStatus(type.value)}
                          sx={{
                            border: "1px solid #D5E1F9",
                            backgroundColor: query.status === type.value
                              ? "#F1F4FF"
                              : "transparent",
                            color: query.status === type.value
                              ? "#1F3A91"
                              : "#6B7280",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight:
                                query.status === type.value ? 600 : 500,
                            }}
                          >
                            {type.label}
                          </Typography>
                        </Button>
                      ))}
                    </StyledButtonGroup>
                  </Box>
                  <Box>
                    <Button
                      size="large"
                      sx={{
                        borderRadius: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        border: "1px solid #1D3DB0",
                        color: "#1D3DB0",
                      }}
                      startIcon={<FileDownloadIcon />}
                      endIcon={<ExpandMoreIcon />}
                    >
                      Download
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            <DataTable
              columns={[
                { label: "ID", id: "id", render: (row) => `#${row.id}` },
                { label: "Name", id: "name" },
                { label: "Phone", id: "phone" },
                { label: "Score", id: "score" },
                { label: "Ordered on", id: "ordered" },
                { label: "Requested By", id: "requestedBy" },
                { label: "Sent Via", id: "sendVia" },
                {
                  label: "Status",
                  id: "status",
                  render: (row) => renderStatus(row.status),
                },
                {
                  label: "Action",
                  id: "action",
                  sortable: true,
                  render: (row) => {
                    return (
                      <TruncatedTooltipText text="">
                        <Box
                          component="img"
                          src={
                            row.status === "completed"
                              ? EditIcon
                              : row.status === "expired"
                                ? ExpiryIcon
                                : PendingIcon
                          }
                          onClick={() => {
                            if (row.status === "completed") {
                              navigate("/report/analysis")
                            }
                          }}
                          sx={{
                            width: { xs: "50px", md: "3.2rem" },
                            height: { xs: "50px", md: "3.2rem" },
                          }}
                        />
                      </TruncatedTooltipText>
                    );
                  },
                },
              ]}
              rows={reportsList || []}
              isLoading={isLoading}
              orderBy={query.orderBy}
              order={query.order}
              createSortHandler={createSortHandler}
              checkboxSelection={true}
            />
            <PaginationControl
              page={page}
              rowsPerPage={rowsPerPage}
              totalItems={totalRecordsCount || 0}
              handlePageChange={handlePageChange}
            />
          </Panel>
        </Grid>
      </Grid>
      <EditModal
        open={open}
        onClose={handleClose}
        handleUpdate={CreateIBARequest}
        updateText="Send Request"
        title="New IBA Request"
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            IBA Request
          </Typography>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "400", color: "#303132" }}
          >
            Manage all IBA request related details here
          </Typography>
          <Box
            sx={{
              padding: "20px",
              background: "#F7FAFF",
              borderRadius: "6px",
              margin: "20px 0 0 0",
            }}
          >
            <SelectComponent
              options={customersList}
              label="Select Customer"
              value={selectedCustomer}
              onChange={(newValue: any) => setSelectedCustomer(newValue)}
              fullWidth
              icon={<UserIcon />}
            />
            <Typography
              sx={{
                marginTop: 2,
                marginBottom: "25px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#111928",
              }}
            >
              Note: Customer does not exist in the database?&nbsp;
              <Typography
                component={"span"}
                sx={{
                  textDecoration: "underline",
                  padding: 0,
                  color: "#264D8E",
                  fontSize: "14px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/customers/new")}
              >
                Create New Customer
              </Typography>
            </Typography>
            <Divider sx={{ border: "0.5px dashed #B1C6F1" }} />
            <FormLabel component="legend" sx={{ marginTop: "20px" }}>
              Send verification via
            </FormLabel>
            <RadioGroup
              value={verification}
              onChange={handleVerificationChange}
              row
            >
              <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
              <FormControlLabel
                value="Email"
                control={<Radio />}
                label="Email"
              />
              <FormControlLabel value="Both" control={<Radio />} label="Both" />
            </RadioGroup>
          </Box>
        </Box>
      </EditModal>
    </Grid>
  );
};

export default IBAReportPage;
