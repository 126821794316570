const SearchIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_72_14592)">
    <path d="M11.742 10.3441C12.7103 9.02279 13.144 7.38459 12.9563 5.75725C12.7687 4.12991 11.9735 2.63344 10.7298 1.56723C9.48616 0.501022 7.88579 -0.0562959 6.24888 0.00677721C4.61197 0.0698504 3.05923 0.748663 1.90131 1.90741C0.743395 3.06615 0.0656939 4.61938 0.00379204 6.25633C-0.0581098 7.89329 0.500353 9.49326 1.56745 10.7361C2.63455 11.979 4.13159 12.7732 5.75906 12.9597C7.38654 13.1462 9.02442 12.7113 10.345 11.7421H10.344C10.374 11.7821 10.406 11.8201 10.442 11.8571L14.292 15.7071C14.4796 15.8947 14.7339 16.0002 14.9992 16.0003C15.2645 16.0004 15.5189 15.8951 15.7065 15.7076C15.8942 15.5201 15.9997 15.2657 15.9997 15.0005C15.9998 14.7352 15.8946 14.4807 15.707 14.2931L11.857 10.4431C11.8213 10.4069 11.7828 10.3735 11.742 10.3431V10.3441ZM12 6.5001C12 7.22237 11.8578 7.93757 11.5814 8.60486C11.305 9.27215 10.8999 9.87847 10.3891 10.3892C9.87841 10.8999 9.27209 11.305 8.6048 11.5814C7.93751 11.8578 7.22231 12.0001 6.50004 12.0001C5.77777 12.0001 5.06258 11.8578 4.39528 11.5814C3.72799 11.305 3.12168 10.8999 2.61096 10.3892C2.10023 9.87847 1.69511 9.27215 1.41871 8.60486C1.14231 7.93757 1.00004 7.22237 1.00004 6.5001C1.00004 5.04141 1.57951 3.64246 2.61096 2.61101C3.64241 1.57956 5.04135 1.0001 6.50004 1.0001C7.95873 1.0001 9.35768 1.57956 10.3891 2.61101C11.4206 3.64246 12 5.04141 12 6.5001Z" fill="#6B7280"/>
    </g>
    <defs>
    <clipPath id="clip0_72_14592">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default SearchIcon