import {
  Grid2 as Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Panel from "../../../components/Panel";
import { LineChart } from "@mui/x-charts";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid #D5E1F9 !important`,
  fontSize: "14px",
  color: "#616773",
  fontWeight: "600",
}));

const EmployementIncome = () => {
  const theme = useTheme();
  return (
    <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
      <Panel
        sx={{
          backgroundColor: theme?.palette?.primary?.main,

          padding: 2,
          color: "white",
          borderRadius: "0px",
          borderBottom: "3px solid #D6C64D",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", fontWeight: "600", color: "#FFFFFF" }}
        >
          Employment & Income – Branch & Knoble
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "500", color: "#FFFFFF" }}
        >
          Since 11/17/2022 - (1 year 9 months)
        </Typography>
      </Panel>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: "#eef7ff" }}>
            <TableRow>
              <CustomTableCell>Employer</CustomTableCell>
              <CustomTableCell>Pay</CustomTableCell>
              <CustomTableCell>Last Deposited</CustomTableCell>
              <CustomTableCell width={"300px"}></CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {financialReport?.likelyEmployers?.map((item: any) => */}
            <TableRow>
              <CustomTableCell>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#333333",
                  }}
                >
                  {/* {item.employerName ?? "Branch & Knoble"} */}
                  {"Branch & Knoble"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#616773",
                  }}
                >
                  Submitted
                </Typography>
              </CustomTableCell>
              <CustomTableCell>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#333333",
                  }}
                >
                  $4,756.13
                </Typography>
              </CustomTableCell>
              <CustomTableCell>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#333333",
                  }}
                >
                  6/15/2024
                </Typography>
              </CustomTableCell>
              <CustomTableCell>
                <LineChart
                  sx={{ height: "100px", width: "100px" }}
                  xAxis={[{ data: ["Feb", "Mar", "May", "Jun", "July"] }]}
                  yAxis={[{ data: [0, 500, 1500, 2000] }]}
                  series={[
                    {
                      data: [1600, 1600, 1600, 1600, 1600],
                    },
                  ]}
                  width={300}
                  height={150}
                  dataset={[
                    { x: 1, y: 1600 },
                    { x: 2, y: 1600 },
                    { x: 3, y: 1600 },
                    { x: 5, y: 1600 },
                    { x: 8, y: 1600 },
                  ]}
                  // xAxis={[{ dataKey: 'x' }]}
                  // series={[{ dataKey: 'y' }]}
                  margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
                  grid={{ vertical: true, horizontal: true }}
                />
              </CustomTableCell>
            </TableRow>
            {/* )} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default EmployementIncome;
