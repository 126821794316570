interface IconProps {
  selected?: boolean;
  width?: string; 
  height?: string; 
}

const BusinessIcon = ({ selected, width = "14", height = "14" }: IconProps) => {
  const fillColor = selected ? "#264D8E" : "#6B7280";

  return (
    <svg width={width} height={height} viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M0.333252 7.16667V15.5H4.49992V10.5H7.83325V15.5H11.9999V7.16667L6.16658 3L0.333252 7.16667Z" 
        fill={fillColor}
      />
      <path 
        d="M7.83325 0.5V2.14167L13.6666 6.30833V7.16667H15.3333V8.83333H13.6666V10.5H15.3333V12.1667H13.6666V15.5H18.6666V0.5H7.83325ZM15.3333 5.5H13.6666V3.83333H15.3333V5.5Z" 
        fill={fillColor}
      />
    </svg>
  );
};

export default BusinessIcon;
