const RoundDollarIcon = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="38" height="38" rx="19" fill="#EFF6FF"/>
    <path d="M19 9C13.48 9 9 13.48 9 19C9 24.52 13.48 29 19 29C24.52 29 29 24.52 29 19C29 13.48 24.52 9 19 9ZM20.41 25.09V27H17.74V25.07C16.03 24.71 14.58 23.61 14.47 21.67H16.43C16.53 22.72 17.25 23.54 19.08 23.54C21.04 23.54 21.48 22.56 21.48 21.95C21.48 21.12 21.04 20.34 18.81 19.81C16.33 19.21 14.63 18.19 14.63 16.14C14.63 14.42 16.02 13.3 17.74 12.93V11H20.41V12.95C22.27 13.4 23.2 14.81 23.26 16.34H21.3C21.25 15.23 20.66 14.47 19.08 14.47C17.58 14.47 16.68 15.15 16.68 16.11C16.68 16.95 17.33 17.5 19.35 18.02C21.37 18.54 23.53 19.41 23.53 21.93C23.52 23.76 22.15 24.76 20.41 25.09Z" fill="#363636"/>
    </svg>
    
  )
}

export default RoundDollarIcon