import styled from '@emotion/styled';
import { Paper } from '@mui/material';

const Panel = styled(Paper)(({ sx = {} as any, theme }) => {
    return {
        backgroundColor: sx?.backgroundColor ?? 'white',
        borderRadius: sx?.borderRadius ?? '5px'
        // padding: theme.spacing(1),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
        // ...theme.applyStyles('dark', {
        //   backgroundColor: '#1A2027',
        // }),
    };
});

export default Panel;