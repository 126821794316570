import AxiosInstance from './http';


/**
 * 
 * @param queryObj
 * @returns
 */
export async function CreateNewIBAReqest(customerId: any, sendVia: string): Promise<any> {
    try {
        const body = {
            CustomerId: customerId,
            SendVia: sendVia == 'SMS' ? ['sms'] : sendVia == 'Email' ? ['email'] : ['sms', 'email']
        };

        const response = await AxiosInstance.post('/InstantBankAnalysis/ReportRequest', body);

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetReportRequestsList(queryObj: any = {}): Promise<any> {
    try {
        const query = { ...queryObj };
        if (query.status === 'All') {
            delete query.status;
        }
        query.order = (query?.order || '')?.toUpperCase();
        const response = await AxiosInstance.get('/InstantBankAnalysis/ReportRequests', {
            params: query
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetTransactionHistory(queryObj: object): Promise<any> {
    try {
        const response = await AxiosInstance.post('/InstantBankAnalysis/getBankingTrans', queryObj);

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetAccountDetails(queryObj: object): Promise<any> {
    try {
        const response = await AxiosInstance.post('/InstantBankAnalysis/getAccountDetails', {
            "userID": "User1",
            "cryptVal": "cashedge1"
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetAccountSummary(queryObj: object): Promise<any> {
    try {
        const response = await AxiosInstance.post('/InstantBankAnalysis/getAccountsSummary', {
            "acctId": "6200566",
            "acctType": "SDA",
            "extAcctType": "SDA",
            "userID": "User1",
            "cryptVal": "cashedge1"
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetFinancialReport(queryObj: object): Promise<any> {
    try {
        queryObj = {
            acctId: 6200569
        }

        const response = await AxiosInstance.get('/InstantBankAnalysis/getFinancialReport', {
            params: queryObj
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};