import React from "react";

const CalenderIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      cursor={"pointer"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 3V3.5H14H14.75C15.2989 3.5 15.75 3.95114 15.75 4.5V15C15.75 15.5489 15.2989 16 14.75 16H4.25C3.69533 16 3.25009 15.5506 3.25 15.0002C3.25 15.0001 3.25 15.0001 3.25 15L3.2575 4.50036V4.5C3.2575 3.94623 3.69853 3.5 4.25 3.5H5H5.5V3V2H6V3V3.5H6.5H12.5H13V3V2H13.5V3ZM14.75 15.5H15.25V15V7.5V7H14.75H4.25H3.75V7.5V15V15.5H4.25H14.75ZM6.25 10V9.5H6.75V10H6.25ZM9.75 10H9.25V9.5H9.75V10ZM12.25 10V9.5H12.75V10H12.25ZM6.25 13V12.5H6.75V13H6.25ZM9.75 13H9.25V12.5H9.75V13ZM12.25 13V12.5H12.75V13H12.25Z"
        fill="#737373"
        stroke="#6B7280"
      />
    </svg>
  );
};

export default  CalenderIcon;
;
