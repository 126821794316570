import AxiosInstance from './http';

export async function RegisterAccount(username: string, password: string) {
    try {
        const response = await AxiosInstance.post('/authentication/login', {
            email: username,
            password
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * @description API used to fetch the basic information and to validate whether user is logged in or not
 * 
 * @returns 
 */
export async function AccountInstrument(): Promise<any> {
    try {
        const response = await AxiosInstance.get('/authentication/whoami');

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};