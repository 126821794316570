import React, { useState, MouseEvent } from 'react';
import { Button, Popover, Grid, Typography, Box, TextField, InputAdornment, useTheme } from '@mui/material';
import CalenderIcon from '../../assets/IconComponents/CalenderIcon';

const currentYear = new Date().getFullYear();

interface YearPickerProps {
  placeholder?: string;
  style?: React.CSSProperties;
  popoverWidth?: string;
  popoverHeight?: string;
  onYearSelect?: (year: number | null) => void;
  borderColor?: string;
  backgroundColor?: string;
}

const YearPicker: React.FC<YearPickerProps> = ({
  placeholder = "Select Year",
  style = {},
  popoverWidth = "300px",
  popoverHeight = "fit-content",
  onYearSelect = () => { },
   borderColor = "#D5E1F9",
   backgroundColor = "#f7f9fc",
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [tempYear, setTempYear] = useState<number | null>(null);
  const [yearRange, setYearRange] = useState<[number, number]>([currentYear - 6, currentYear + 5]);

  const theme = useTheme();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleYearSelect = (year: number) => {
    setTempYear(year);
  };

  const handleNextRange = () => {
    setYearRange([yearRange[0] + 12, yearRange[1] + 12]);
  };

  const handlePreviousRange = () => {
    setYearRange([yearRange[0] - 12, yearRange[1] - 12]);
  };

  const handleOk = () => {
    setSelectedYear(tempYear);
    handleClose();
    if (onYearSelect) {
      onYearSelect(tempYear);
    }
  };

  const handleCancel = () => {
    setTempYear(null);
    setSelectedYear(null);
    handleClose();
    if (onYearSelect) {
      onYearSelect(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'year-popover' : undefined;

  const yearsToDisplay = Array.from({ length: yearRange[1] - yearRange[0] + 1 }, (_, i) => yearRange[0] + i);

  return (
    <div>
      <TextField
        placeholder={placeholder}
        fullWidth
        sx={{
          fontSize: "14px",
          background: {backgroundColor},
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            color: "#6B7280 !important",
            height:"48px !important",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor, 
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor, 
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor,
            borderRadius: "8px",
          },
          ...style,
        }}
        size="small"
        variant="outlined"
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ display: "flex", alignItems: "center", width: "18px", height: "18px" }}>
              <CalenderIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={selectedYear !== null ? selectedYear : ''}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: popoverWidth,
            height: popoverHeight,
            gap: '10px',
            borderRadius: '8px',
            opacity: 1,
          },
        }}
      >
        <Box sx={{ marginTop: '10px', padding: "5px" }}>
          <Grid container display={"flex"} justifyContent="space-between" alignItems="center">
            <Button
              sx={{ width: "20px", height: "20px" }}
              onClick={handlePreviousRange}
            >
              {"<"}
            </Button>
            <Typography sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "18px" }}>
              {yearRange[0]} - {yearRange[1]}
            </Typography>
            <Button
              sx={{ width: "20px", height: "20px" }}
              onClick={handleNextRange}
            >
              {">"}
            </Button>
          </Grid>

          <Grid container justifyContent="center" sx={{ marginTop: '10px' }}>
            {yearsToDisplay.map((year) => (
              <Grid item key={year} p={"0"}>
                <Button
                  variant={tempYear === year ? 'contained' : 'text'}
                  onClick={() => handleYearSelect(year)}
                  sx={{
                    width: '36px',
                    height: '34px',
                    borderRadius: '1vw',
                    padding: tempYear === year ? "16px" : "0",
                    backgroundColor: tempYear === year ? theme.palette.primary.main : 'transparent',
                    color: tempYear === year ? '#fff' : '#374151',
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "18px",
                  }}
                >
                  {year}
                </Button>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"center"}>
            <Box display="flex" justifyContent="center" mt={2} width={"90%"}>
              <Button
                variant="contained"
                onClick={handleOk}
                sx={{
                  background: theme.palette.primary.main,
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "45%",
                }}
              >
                OK
              </Button>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{
                  marginRight: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "45%",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default YearPicker;
