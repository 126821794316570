const IBADownArrow = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4982 4.03938L2.49822 4.03938C2.34635 4.03986 2.19749 4.08174 2.06766 4.16053C1.93782 4.23932 1.83193 4.35202 1.76139 4.48651C1.69084 4.621 1.65831 4.77218 1.6673 4.92379C1.67629 5.07539 1.72645 5.22167 1.81239 5.34688L9.31239 16.1802C9.62322 16.6294 10.3716 16.6294 10.6832 16.1802L18.1832 5.34688C18.27 5.22193 18.3209 5.07557 18.3304 4.92372C18.3399 4.77186 18.3076 4.62032 18.237 4.48554C18.1663 4.35077 18.0602 4.23792 17.9299 4.15926C17.7997 4.0806 17.6504 4.03914 17.4982 4.03938Z"
        fill="#E23C1F"
      />
    </svg>
  );
};

export default IBADownArrow;
