import styled from '@emotion/styled';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid2 as Grid,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut } from "../../api/AuthAPI";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as BankSureLogo } from '../../assets/media/svg/banksure-logo.svg';
import { AppContext } from "../../context/AppContext";
import NotificationsActiveIcon from '../../assets/IconComponents/NotificationsActiveIcon';

const IconWrapper = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  height: 70,
  marginTop: -20,
  marginLeft: 20,
}));

const HeaderComponent = ({ toggleSideNav }: { toggleSideNav: () => void }) => {
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(max-width:1024px)");
  const { app: appCtx } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onLogOut = async () => {
    try {
      const response = await LogOut();
      closeProfileMenu();
      navigate("/auth/login");
    } catch (err) {
      console.error("Error in logout");
    }
  };

  const openProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {!isLargeScreen ? <AppBar
        component={'nav'}
        position="static"
        sx={{
          height: "70px",
          backgroundColor: "white",
          boxShadow: "0px 2px 10px -10px black",
          width: isLargeScreen ? "100%" : "calc(100% - 300px)",
          marginLeft: isLargeScreen ? 0 : "300px",
          display: "flex",
        }}
      >
        <Grid container sx={{ padding: 1 }}>
          <Grid size={6}>
            <Typography
              color="black"
              variant="h6"
              sx={{ marginTop: "10px", marginLeft: "20px" }}
              fontWeight={600}
            >
              {appCtx.pageTitle}
            </Typography>
          </Grid>
          <Grid size={6} textAlign={"right"} spacing={2}>
            <IconButton>
              <NotificationsActiveIcon />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: 0,
              }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openProfileMenu}
              color="inherit"
            >
              <Box
                component="img"
                sx={{
                  height: "40px",
                  width: "40px",
                }}
                alt="User Avatar"
                src={`https://ui-avatars.com/api/?rounded=true&name=${appCtx.user.firstName || "Demo"
                  }+${appCtx.user.lastName || "User"}`}
              />
              <Typography color="black" sx={{ marginLeft: "10px" }}>
                {`${appCtx.user.firstName || "Demo"} ${appCtx.user.lastName || "User"
                  }`}
              </Typography>
              {!!anchorEl ? (
                <KeyboardArrowUpIcon sx={{ color: "black" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "black" }} />
              )}
            </IconButton>
            <Menu
              open={Boolean(anchorEl)}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={closeProfileMenu}
            >
              <MenuItem onClick={onLogOut}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </AppBar> : <AppBar
        component={'nav'}
        position="static"
        sx={{
          height: "70px",
          backgroundColor: "white",
          boxShadow: "0px 2px 10px -10px black",
          width: "100%",
          marginLeft: 0,
          display: "flex",
        }}
      >
        <Grid container size={12}>
          <Grid size={3}>
            <IconButton onClick={toggleSideNav} sx={{ marginTop: '10px' }}>
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
          </Grid>
          <Grid size={6} textAlign={'center'} >
            <IconWrapper>
              <BankSureLogo height={100} width={100} />
            </IconWrapper>
          </Grid>
          <Grid size={3}>
          </Grid>
        </Grid>
      </AppBar>}
    </Fragment>
  );
};

export default HeaderComponent;
