import { Box, Button, ButtonGroup, Card, Divider, Grid2 as Grid, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import Panel from '../../components/Panel';
import StackedBarChart from './StackedBarChart';
import SelectComponent from '../../components/form/SelectComponent';


import { ReactComponent as CompletedRequstsIcon } from '../../assets/media/svg/CompletedRequests.svg';
import { ReactComponent as PendingRequstsIcon } from '../../assets/media/svg/PendingRequests.svg';
import { ReactComponent as ExpiredRequstsIcon } from '../../assets/media/svg/ExpiredRequests.svg';
import { ReactComponent as CalenderIcon } from '../../assets/media/svg/CalenderIcon.svg';
import TopTenCustomerChart from './TopTenCustomerChart';
import InstanceBankAnalysisReport from './InstanceBankAnalysisReport';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";

interface OptionType {
    value: string | number;
    label: string;
}

const DashboardPage = (props: any) => {

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme?.breakpoints?.down("sm"));

    const options: OptionType[] = [
        { label: 'All', value: '1' },
        { label: 'All', value: '2' },
        { label: 'All', value: '3' },
    ];

    const handleChange = (selectedOption: OptionType | null) => {
        console.log('Selected option:', selectedOption);
    };

    return (
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid size={12} columnSpacing={3}>
                <Panel sx={{ width: '100%', padding: '15px', boxShadow: 'none', borderRadius: '8px' }}>
                    <Grid container>
                        <Grid size={9}>
                            <Typography variant='h6' fontSize={'bold'}>Welcome Back, Michael</Typography>
                        </Grid>
                        {/* <Grid size={3}>
                            <Typography>View real-time reports</Typography>
                        </Grid> */}
                        <Grid size={3} container>
                            <Grid size={6}>
                                <Typography>View Real Time</Typography>
                            </Grid>
                            <Grid size={6}>
                                <Switch defaultChecked sx={{
                                    width: 48,
                                    height: 24,
                                    padding: 0,
                                    '& .MuiSwitch-switchBase': {
                                        padding: 2,
                                        transform: 'translateX(2px)',
                                        '&.Mui-checked': {
                                            transform: 'translateX(22px)',
                                            color: '#fff',
                                            '& + .MuiSwitch-track': {
                                                backgroundColor: '#1976d2',
                                                opacity: 1,
                                            },
                                        },
                                    },
                                    '& .MuiSwitch-thumb': {
                                        width: 20,
                                        height: 20,
                                    },
                                    '& .MuiSwitch-track': {
                                        borderRadius: 12,
                                        backgroundColor: '#bdbdbd',
                                        opacity: 1,
                                    },
                                }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Panel>
            </Grid>

            <Grid container size={12}>
                <Grid size={3}>
                    <Panel sx={{ padding: '10px' }}>
                        <Grid container>
                            <Grid size={8} container>
                                <Grid size={12}>
                                    <Typography variant={'h5'}>
                                        800
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Typography>
                                        Requests this month
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid size={4}>
                                <CalenderIcon />
                            </Grid>
                            <Grid size={12}>
                                5.45% up from 780 since last month
                            </Grid>
                        </Grid>
                        {/* <Typography>
                            5.45% up from 780 since last month
                        </Typography> */}
                    </Panel>
                </Grid>
                <Grid size={3}>
                    <Panel sx={{ padding: '10px' }}>
                        <Grid container>
                            <Grid size={8} container>
                                <Grid size={12}>
                                    <Typography variant={'h5'}>
                                        420
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Typography>
                                        Completed Requests
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid size={4}>
                                <CompletedRequstsIcon />
                            </Grid>
                            <Grid size={12}>
                                5.5% up from 330 since last month
                            </Grid>
                        </Grid>
                        {/* <Typography>
                            5.5% up from 330 since last month
                        </Typography> */}
                    </Panel>
                </Grid>
                <Grid size={3}>
                    <Panel sx={{ padding: '10px' }}>
                        <Grid container>
                            <Grid size={8} container>
                                <Grid size={12}>
                                    <Typography variant={'h5'}>
                                        200
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Typography>
                                        Pending Requests
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid size={4}>
                                <PendingRequstsIcon />
                            </Grid>
                            <Grid size={12}>
                                16.67% down from 230 since last month
                            </Grid>
                        </Grid>
                        {/* <Typography>
                            5.5% up from 330 since last month
                        </Typography> */}
                    </Panel>
                </Grid>
                <Grid size={3}>
                    <Panel sx={{ padding: '10px' }}>
                        <Grid container>
                            <Grid size={8} container>
                                <Grid size={12}>
                                    <Typography variant={'h5'}>
                                        180
                                    </Typography>
                                </Grid>
                                <Grid size={12}>
                                    <Typography>
                                        Expired Requests
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid size={4}>
                                <ExpiredRequstsIcon />
                            </Grid>
                            <Grid size={12}>
                                11.1% down from 200 since last month
                            </Grid>
                        </Grid>
                        {/* <Typography>
                            5.5% up from 330 since last month
                        </Typography> */}
                    </Panel>
                </Grid>
                {/* <Grid size={3}>
                    <Panel sx={{ padding: '50px' }}>
                        <Typography>Card 5</Typography>
                    </Panel>
                </Grid> */}
            </Grid>

            {/* <Grid container size={12}>
                <Grid size={7}>
                    <Panel>
                        <Grid container size={12}>
                            <Grid size={8}>
                                <Grid container>
                                    <Grid>date field</Grid>
                                    <Grid></Grid>
                                </Grid>
                            </Grid>
                            <Grid size={4}>
                                <Grid>graph1</Grid>
                                <Grid>graph2</Grid>
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                <Grid size={5}>
                    <Panel>
                        <Grid container size={12}>
                            <Grid size={12}>Text Field</Grid>
                            <Grid size={12}>Graph 1</Grid>
                        </Grid>
                    </Panel>
                </Grid>
            </Grid> */}

            <Grid spacing={2.5} size={12} container direction={'row'}>
                <Grid size={6.5}>
                    <Card sx={{ padding: 3, minHeight: '450px' }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                            <Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#374151' }}>
                                    IBA Requests
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#6B7280' }}>
                                    This week
                                </Typography>
                            </Box>
                            <Box>
                                <SelectComponent options={options} onChange={handleChange} placeholder='All' />
                            </Box>
                        </Box>
                        <Divider />
                        <Box marginTop={"10px"}>
                            <StackedBarChart />
                        </Box>
                    </Card>
                </Grid>

                <Grid size={4.5} >
                    <Card sx={{ width: '600px', padding: 3, minHeight: '450px' }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                            <Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#374151' }}>
                                    Top 10 Customers
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#6B7280' }}>
                                    This week
                                </Typography>
                            </Box>
                            <Box>
                                <SelectComponent options={options} onChange={handleChange} placeholder='All' />
                            </Box>
                        </Box>
                        <Divider />
                        <Box marginTop={"10px"}>
                            <TopTenCustomerChart />
                        </Box>
                    </Card>
                </Grid>
            </Grid>

            <Grid container size={12}>
                <Panel sx={{ width: "100%" }}>
                    <Box sx={{ padding: 1.5 }}>
                        <Grid size={12} sx={{ marginY: 3 }}>
                            <Grid container spacing={1} textAlign={'left'}>
                                <Grid size={{ xl: 7, lg: 6, md: 12, sm: 12, xs: 12 }}>
                                    {/* <OutlinedInput
                                        fullWidth
                                        placeholder="Search"
                                        startAdornment={<SearchIcon />}
                                    /> */}
                                    <Typography variant='h5'>
                                        Instant Bank Analysis Report <span>Recent 10 reports</span>
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        Customer Report
                                    </Typography>
                                </Grid>
                                {/* <Grid size={{ xl: 2, lg: 2, md: 6, sm: 6, xs: 6 }}>
                                    <OutlinedInput placeholder='April' /> 

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker label={""} views={["month", "year"]} />
                                </LocalizationProvider>
                            </Grid> */}
                                {/* <Grid size={1.5}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <OutlinedInput placeholder='2024' />
                                        </LocalizationProvider>
                                    </Grid> */}
                                <Grid size={{ xl: 3, lg: 4, md: 6, sm: 6, xs: 6 }}>
                                    <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{ height: '53px' }}>
                                        <Button>
                                            <Typography sx={{}} variant='caption'>
                                                Score
                                            </Typography>
                                        </Button>
                                        <Button>
                                            <Typography sx={{}} variant='caption'>
                                                Expired Requests
                                            </Typography>
                                        </Button>
                                        <Button>
                                            <Typography sx={{}} variant='caption'>
                                                Pending Requests
                                            </Typography>
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid size={{ xl: 2, lg: 2, md: 6, sm: 6, xs: 6 }} textAlign={'right'}>
                                    <Button
                                        color='primary'
                                        variant="outlined"
                                        // startIcon={<FileDownloadIcon />}
                                        sx={{ height: '50px', width: isSmallScreen ? '80px' : '150px' }}
                                    >
                                        Resend All (2)
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid size={12}>
                            <InstanceBankAnalysisReport />
                        </Grid>
                    </Box>
                </Panel>
            </Grid>
        </Grid >
    );
};

export default DashboardPage;
