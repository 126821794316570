import { createBrowserRouter } from 'react-router-dom';

import AuthRouter from './authRouter';
import MainRouter from './mainRouter';
import DashboardLayout from '../layout/dashboard';
import NotFoundPage from '../pages/misc/NotFound';
import AuthLayout from '../layout/AuthLayout';

const AppRouter = createBrowserRouter([
    {
        path: 'auth',
        element: <AuthLayout />,
        children: AuthRouter
    },
    {
        path: '',
        element: <DashboardLayout />,
        children: MainRouter
    },
    {
        path: '*',
        Component: NotFoundPage
    }
]);

export default AppRouter;
