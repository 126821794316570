import { Box, Typography } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";

interface BarChartProps {
  data: any;
  title?: string;
  subTitle?: string;
  threshold?: number; 
  axis?: "x" | "y";
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  title,
  subTitle,
  threshold = 0, 
  axis = "y",     
}) => {
 
  const updatedData = {
    ...data,
    datasets: data.datasets.map((dataset: any) => ({
      ...dataset,
      backgroundColor: dataset.data.map((value: number) =>
        value >= threshold ? "#FE7F7F" : "#1D4F9E"
      ),
    })),
  };

  const options = {
    responsive: true,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box sx={{display:"flex",justifyContent:"center",flexDirection:"column",width:"700px"}}>
      {title && (
        <Box
          sx={{
            background: "#F0F8FE",  
            width: "fit-content",
            padding: "10px",
            marginBottom: 2,
            borderRadius: "8px",
          }}
        >
          <Typography
            component={"span"}
            sx={{ fontSize: "14px", fontWeight: "700", color: "#1A2D51" }}
          >
            {title}{" "}
          </Typography>
          <Typography
            component={"span"}
            sx={{ fontSize: "14px", fontWeight: "800", color: "#2553A8" }}
          >
            {subTitle}
          </Typography>
        </Box>
      )}
      <Bar data={updatedData} options={options} />
    </Box>
  );
};

export default BarChart;
