import {
  Box,
  Grid2 as Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import Panel from "../../../components/Panel";
import moment from "moment";
import IBAUpArrow from "../../../assets/IconComponents/IBAUpArrow";
import IBADownArrow from "../../../assets/IconComponents/IBADownArrow";

interface IncomeOverviewRowProps {
  label: string;
  value: string;
  color: string;
  trend: string;
}

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid #D5E1F9 !important`,
  fontSize: "14px",
  color: "#616773",
  fontWeight: "600",
}));

interface FinancialReport {
  currentBalance: number;
  availableBalance: number;
}

const SummaryAccount = ({
  financialReport,
}: {
  financialReport: FinancialReport;
}) => {
  const theme = useTheme();

  const data = [
    { label: "Income Available", value: "13%", color: "#1D4F9E", trend: "up" },
    { label: "Income Spent", value: "87%", color: "#FF6D6D", trend: "down" },
    { label: "Income Saved", value: "13%", color: "#4599EE", trend: "up" },
  ];

  const IncomeOverviewRow: React.FC<
    IncomeOverviewRowProps & { isLast?: boolean }
  > = ({ label, value, color, trend, isLast }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: isLast ? "none" : "1px solid #D5E1F9",
        }}
      >
        <Box
          sx={{
            width: "21px",
            height: "8px",
            backgroundColor: color,
            marginRight: "12px",
          }}
        />
        <Typography
          sx={{ color: "#282828", fontSize: "14px", fontWeight: 400, flex: 1 }}
        >
          {label}
        </Typography>
        <Typography
          sx={{ color: "#282828", fontSize: "14px", fontWeight: 700 }}
        >
          {value}
        </Typography>
        <Box
          sx={{ border: "0.8px solid #D5E1F9", height: "20px", marginX: "5px" }}
        />
        <Box>{trend === "up" ? <IBAUpArrow /> : <IBADownArrow />}</Box>
      </Box>
    );
  };

  return (
    <>
      <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
        <Panel
          sx={{
            backgroundColor: theme?.palette?.primary?.main,
            padding: 2,
            color: "white",
            borderRadius: 0,
            borderBottom: "3px solid #D6C64D",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Summary by Account
          </Typography>
        </Panel>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#EFF7FF" }}>
              <TableRow>
                <CustomTableCell>Financial Institiaton</CustomTableCell>
                <CustomTableCell>Account</CustomTableCell>
                <CustomTableCell>First Transaction</CustomTableCell>
                <CustomTableCell>Account Type</CustomTableCell>
                <CustomTableCell>Available Balance</CustomTableCell>
                <CustomTableCell>Avg Daily Balance</CustomTableCell>
                <CustomTableCell>Current Balance</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CustomTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "48px",
                        height: "48px",
                        backgroundColor: "#E3F2FD",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexShrink: 0,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                            color: "#2A61A5",
                          fontWeight: "700",
                          fontSize: "15px",
                        }}
                      >
                        BH
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#000000",
                        wordWrap: "break-word",
                        maxWidth: "200px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Bank of Hawaii
                    </Typography>
                  </Box>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    ****9511
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {moment("10/22/2024").format("DD/MM/YYYY")}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333333",
                    }}
                  >
                    Checking
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#333333",
                    }}
                  >
                    ${financialReport.currentBalance ?? "376.74"}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#333333",
                    }}
                  >
                    {"$71.15"}
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#333333",
                      textAlign: "right",
                    }}
                  >
                    ${financialReport.availableBalance ?? "471.23"}
                  </Typography>
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
        <Panel
          sx={{
            backgroundColor: theme?.palette?.primary?.main,
            padding: "3px",
            borderRadius: 0,
            borderBottom: "3px solid #D6C64D",
          }}
        ></Panel>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#EFF7FF" }}>
              <TableRow>
                <CustomTableCell>Income Probability</CustomTableCell>
                <CustomTableCell>Est. Annual Income</CustomTableCell>
                <CustomTableCell>Ability to Pay Monthly (Max)</CustomTableCell>
                <CustomTableCell>
                  Ability to Pay Bi-weekly (Max)
                </CustomTableCell>
                <CustomTableCell>Available Balance</CustomTableCell>
                <CustomTableCell>Income Usage</CustomTableCell>
                <CustomTableCell>DTI</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#171717",
                    }}
                  >
                    {"90"}%
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    $118,903.25
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    $9512.26
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333333",
                    }}
                  >
                    $3,440.24
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#333333",
                    }}
                  >
                    $1,720.12
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Box
                    sx={{
                      width: "210px",
                      borderRadius: "8px",
                      backgroundColor: "#fff",
                    }}
                  >
                    {data.map((item, index) => (
                      <IncomeOverviewRow
                        key={index}
                        label={item.label}
                        value={item.value}
                        color={item.color}
                        trend={item.trend}
                        isLast={index === data.length - 1}
                      />
                    ))}
                  </Box>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#333333",
                    }}
                  >
                    {"36"}%
                  </Typography>
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default SummaryAccount;
