import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = (props: any) => {
    return <p>
        Page Not Found. please go back.
        <Link to='/dashboard'>
            <Button>
            </Button>
        </Link>
    </p>
};

export default NotFoundPage;
