const ThreeDots = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="38" height="38" rx="19" fill="#EFF6FF"/>
    <path d="M19 9C13.48 9 9 13.48 9 19C9 24.52 13.48 29 19 29C24.52 29 29 24.52 29 19C29 13.48 24.52 9 19 9ZM14 20.5C13.17 20.5 12.5 19.83 12.5 19C12.5 18.17 13.17 17.5 14 17.5C14.83 17.5 15.5 18.17 15.5 19C15.5 19.83 14.83 20.5 14 20.5ZM19 20.5C18.17 20.5 17.5 19.83 17.5 19C17.5 18.17 18.17 17.5 19 17.5C19.83 17.5 20.5 18.17 20.5 19C20.5 19.83 19.83 20.5 19 20.5ZM24 20.5C23.17 20.5 22.5 19.83 22.5 19C22.5 18.17 23.17 17.5 24 17.5C24.83 17.5 25.5 18.17 25.5 19C25.5 19.83 24.83 20.5 24 20.5Z" fill="#363636"/>
    </svg>
    
  );
};

export default ThreeDots;
