interface UserIconProps {
  selected?: boolean;
  width?: string; 
  height?: string; 
}

const UserIcon = ({ selected, width = "14", height = "14" }: UserIconProps) => {
  return (
    <svg
      width={width} 
      height={height} 
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8335 3.66732C9.8335 5.23284 8.56569 6.50065 7.00016 6.50065C5.43464 6.50065 4.16683 5.23284 4.16683 3.66732C4.16683 2.10179 5.43464 0.833984 7.00016 0.833984C8.56569 0.833984 9.8335 2.10179 9.8335 3.66732ZM0.833496 12.0007C0.833496 11.6301 1.01514 11.2686 1.40139 10.9121C1.79103 10.5525 2.35463 10.2328 3.01839 9.96688C4.34671 9.43475 5.95017 9.16732 7.00016 9.16732C8.05016 9.16732 9.65362 9.43475 10.9819 9.96688C11.6457 10.2328 12.2093 10.5525 12.5989 10.9121C12.9852 11.2686 13.1668 11.6301 13.1668 12.0007V13.1673H0.833496V12.0007Z"
        fill={selected ? "#264D8E" : "#737373"} 
        stroke={selected ? "#264D8E" : "#737373"} 
      />
    </svg>
  );
};

export default UserIcon;
