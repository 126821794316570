import {
  styled,
  TableCell,
  useTheme,
  Grid2 as Grid,
  Typography,
  TableBody,
  TableHead,
  Table,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import Panel from "../../../components/Panel";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #D5E1F9 !important",
  fontSize: "14px",
  color: "#616773",
  fontWeight: "600",
}));

const PotentialLoans = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
      <Panel
        sx={{
          backgroundColor: theme?.palette?.primary?.main,
          padding: 2,
          color: "white",
          borderRadius: "0px",
          borderBottom: "3px solid #D6C64D",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
          Potential Loans (3)
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          $180.00
        </Typography>
      </Panel>
      <Grid size={12} container spacing={1}>
        {!isSmallScreen && (
          <>
            <Grid size={{ sm: 6 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Payee</CustomTableCell>
                    <CustomTableCell>Amount</CustomTableCell>
                    <CustomTableCell>Date</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Bear Lending
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $90.00
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        10/04/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Blue Frog
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $90.00
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        10/04/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid size={{ sm: 6 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Payee</CustomTableCell>
                    <CustomTableCell>Amount</CustomTableCell>
                    <CustomTableCell>Date</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Bear Lending
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $90.00
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        10/04/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Blue Frog
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $90.00
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        10/04/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </>
        )}

        {isSmallScreen && (
          <Grid size={{ xs: 12 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Payee</CustomTableCell>
                  <CustomTableCell>Amount</CustomTableCell>
                  <CustomTableCell>Date</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <CustomTableCell>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#333333",
                      }}
                    >
                      Bear Lending
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography
                      sx={{
                        color: "#797979",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      $90.00
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography
                      sx={{
                        color: "#797979",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      10/04/2024
                    </Typography>
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#333333",
                      }}
                    >
                      Blue Frog
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography
                      sx={{
                        color: "#797979",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      $90.00
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography
                      sx={{
                        color: "#797979",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      10/04/2024
                    </Typography>
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PotentialLoans;
