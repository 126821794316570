import { Fragment, useContext, useEffect } from "react";
import { AppContext } from '../../context/AppContext';

/**
 * @description This page act a wrapper for main content.
 * TODO:// Make this component as suspeseful and lazy loaded for feature modules
 *
 */
const Page = ({ component, title }: any) => {
    const { app: appCtx, updateCtx } = useContext(AppContext);
    useEffect(() => {
        updateCtx({
            ...appCtx,
            pageTitle: title
        });
        document.title = title || "";
    }, [title]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return <Fragment key={title}>
        {component}
    </Fragment>
};

export default Page;
