import { useNotification } from "../context/NotificationContext";

export const useAlertMessage = () => {
  const { showNotification } = useNotification();

  const successNotification = (title: string, message: string) => {
    showNotification(title, message, 'success');
  };

  const errorNotification = (title: string, message: string) => {
    showNotification(title, message, 'error');
  };

  const infoNotification = (title: string, message: string) => {
    showNotification(title, message, 'info');
  };

  const warningNotification = (title: string, message: string) => {
    showNotification(title, message, 'warning');
  };

  return { successNotification, errorNotification, infoNotification, warningNotification };
};
