import React, { createContext, useCallback, useContext, useState, ReactNode } from "react";
import { Alert, IconButton, Box, Snackbar, SlideProps, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { CheckCircle, ErrorRounded } from "@mui/icons-material";
import "./index.css"

type Severity = "info" | "warning" | "success" | "error";

interface NotificationState {
  message: string;
  title: string;
  severity: Severity;
  open: boolean;
}

interface NotificationContextType {
  showNotification: (title: string, message: string, severity: Severity) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationState>({
    message: '',
    title: '',
    severity: 'info',
    open: false,
  });

  const showNotification = useCallback((title: string, message: string, severity: Severity) => {
    setNotification({ title, message, severity, open: true });
  }, []);

  const closeNotification = useCallback(() => {
    setNotification((prev) => ({ ...prev, open: false }));
  }, []);

  const iconMapping = {
    info: <InfoIcon sx={{ color: "#005FFF" }} />,
    warning: <WarningIcon sx={{ color: "#ED9902" }} />,
    success: <CheckCircle sx={{ color: "#2E933C" }} />,
    error: <ErrorRounded sx={{ color: "#FF220C" }} />,
  };

  const borderColorMapping = {
    info: "#005FFF",
    warning: "#ED9902",
    success: "#2E933C",
    error: "#FF220C",
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Snackbar
        sx={{ top: '80px !important' }}
        open={notification.open}
        onClose={closeNotification}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        ClickAwayListenerProps={{ onClickAway: () => { } }}     // Stop closing the notification on click away
      >
        <Alert
          severity={notification.severity}
          icon={false}
          sx={{
            display: "flex",
            alignItems: "center",
            border: `1px solid ${borderColorMapping[notification.severity]}`,
            borderRadius: "8px",
            width: "fit-content",
            backgroundColor: "white",
            padding: "0 10px 0 8px",
            height: '65px'
          }}
        >
          <Box
            sx={{
              backgroundColor: borderColorMapping[notification.severity],
              width: "6px",
              height: "40px",
              alignSelf: 'center',
              marginRight: "10px",
              borderRadius: "8px",
            }}
          />

          <Box sx={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
            {iconMapping[notification.severity]}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <strong
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#374151",
                }}
              >
                {notification.title}
              </strong>
            </Box>
            <Box>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#374151",
                }}
              >
                {notification.message}
              </div>
            </Box>
          </Box>

          <Box>
            <IconButton
              aria-label="close"
              size="small"
              onClick={closeNotification}
              sx={{ color: "#5E5E5E" }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>

        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};
