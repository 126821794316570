import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Link,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import Panel from "../../components/Panel";
import InputComponent from "../../components/form/input";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import UserIcon from "../../assets/IconComponents/UserIcon";
import BusinessIcon from "../../assets/IconComponents/BussinessIcon";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import "./index.css";
import DownAccordianArrow from "../../assets/IconComponents/DownAccordianArrow";
import SelectComponent from "../../components/form/SelectComponent";
import CustomerOverview from "./CustomerOverview";
import contactInformation from "../../assets/media/icons/Contact_Information.png";
import customerInformationIcon from "../../assets/media/icons/Customer_Information.png";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import NoDeatilsInformation from "../../assets/IconComponents/NoDetailsIcon";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import Homework from "../../assets/IconComponents/Homework";
import Location from "../../assets/IconComponents/Location";
import MyLocation from "../../assets/IconComponents/MyLocation";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { CreateCustomer } from "../../api/customerAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import { Controller, useForm } from "react-hook-form";
import { FormData, TabPanelProps } from "./types";
import States from '../../assets/json/states.json';

const countries = Object.keys(States).map(state => ({
  value: state,
  label: state
}));

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: "20px", pl: 0, pr: 0 }}>{children}</Box>
      )}
    </div>
  );
};

const AddNewCustomer = () => {
  const theme = useTheme();
  const notification = useAlertMessage();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(max-width:1023px)");
  const isMiniDesktopScreen = useMediaQuery(
    "(min-width:1023px) and (max-width:1439px)"
  );
  const [currentTab, toggleTab] = useState(0);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [cityList, setCityList] = useState<any[]>([]);
  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    "businessPanel1"
  );

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleBusinessAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpandedBusiness(isExpanded ? panel : false);
    };

  const onTabClick = (event: React.SyntheticEvent, newValue: number) => {
    toggleTab(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/customers"}
      sx={{ width: "120px", display: "flex" }}
    >
      <PersonAddIcon
        sx={{ marginRight: 1, color: theme.palette.primary.main }}
      />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Customers
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      Add New Customer
    </Typography>,
  ];

  const {
    control: customerControl,
    handleSubmit: handleCustomerSubmit,
    watch: watchCustomer,
    formState: { errors: customerErrors, isValid: isCustomerValid },
    reset: resetCustomerForm,
  } = useForm<FormData>({ mode: "onChange" });

  const {
    control: businessControl,
    handleSubmit: handleBusinessSubmit,
    watch: watchBusiness,
    formState: { errors: businessErrors, isValid: isBusinessValid },
    reset: resetBusinessForm
  } = useForm<FormData>({ mode: "onChange" });

  const handleCustomerCancel = () => {
    resetCustomerForm();
  };

  const handleBusinessCancel = () => {
    resetBusinessForm();
  };

  const onSubmit = async (data: FormData) => {
    let customerObj;

    if (currentTab === 1) {
      customerObj = {
        customerType: "Business",
        companyName: data.business.businessName,
        firstName: data.business.firstName,
        lastName: data.business.lastName,
        phoneType: data.business.selectedPhone,
        phoneNumber: data.business.phoneNumber,
        email: data.business.email,
        addressLine1: data.business.address1,
        addressLine2: data.business.address2,
        city: data.business.city,
        state: data.business.state,
        postalCode: data.business.postalCode,
        userName: data.business.email.split('@')[0],
      };
    } else {
      customerObj = {
        customerType: "Customer",
        firstName: data.customer.firstName,
        lastName: data.customer.lastName,
        phoneType: data.customer.selectedPhone,
        phoneNumber: data.customer.phoneNumber,
        email: data.customer.email,
        addressLine1: data.customer.address1,
        addressLine2: data.customer.address2,
        city: data.customer.city,
        state: data.customer.state,
        postalCode: data.customer.postalCode,
        userName: data.customer.email.split('@')[0],
      };
    }
    try {
      const result: any = await CreateCustomer(customerObj);
      if (result.statusCode === 200) {

        const parsedResult = result.content ? JSON.parse(result.content) : {};

        if (parsedResult?.UserAddRs?.Status?.StatusCode === 0) {
          notification.successNotification(
            "Success",
            "Customer added successfully"
          );
        } else {
          notification.errorNotification(
            "Error",
            parsedResult?.UserAddRs?.Status?.StatusDesc || "Something went wrong while creating a customer"
          );
          return
        }

        navigate("/customers");
      } else {
        notification.errorNotification(
          "error",
          result.content
        );
      }
    } catch (err) {
      console.error("Error creating customer:", err);
    }
  };

  const hasCustomerDetails =
    watchCustomer("customer.firstName") ||
    watchCustomer("customer.lastName") ||
    watchCustomer("customer.address1") ||
    watchCustomer("customer.address2") ||
    watchCustomer("customer.state") ||
    watchCustomer("customer.city") ||
    watchCustomer("customer.postalCode") ||
    watchCustomer("customer.phoneNumber") ||
    watchCustomer("customer.selectedPhone") ||
    watchCustomer("customer.email");

  const hasBusinessDetails =
    watchBusiness("business.businessName") ||
    watchBusiness("business.address1") ||
    watchBusiness("business.address2") ||
    watchBusiness("business.state") ||
    watchBusiness("business.city") ||
    watchBusiness("business.postalCode") ||
    watchBusiness("business.phoneNumber") ||
    watchBusiness("business.selectedPhone") ||
    watchBusiness("business.email");
  watchCustomer("business.firstName") || watchCustomer("business.lastName");

  const onStateSelect = (state: string) => {
    setCityList((States as any)[state].map((city: string) => ({
      value: city,
      label: city
    })));
  }

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
       
          {breadcrumbs}
         
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={onTabClick}
              aria-label="icon position tabs example"
              sx={{
                ".Mui-selected": {
                  color: "#264D8E !important",
                  fontWeight: "700 !important",
                  marginLeft: "5px",
                  background: "#EFF3FF",
                  minHeight: "56px !important",
                  minWidth: "221px !important",
                  height: "56px !important",
                  borderTopLeftRadius: "8px",
                  padding: "16px !important",
                },
                ".MuiTabs-flexContainer": {
                  borderBottom: "1px solid #d5e1f9",
                },
                "MuiBox-root": {
                  borderBottom: "none !important",
                },
                ".css-1gsv261": {
                  borderBottom: "none",
                },
                ".MuiTab-root": {
                  fontSize: "16px",
                  fontWeight: "600",
                  minWidth: "221px !important",
                  minHeight: "56px !important",
                  "*:first-child": {
                    marginRight: "8px",
                  },
                },
                ".MuiTabs-indicator": {
                  backgroundColor: "#264D8E",
                },
              }}
            >
              <Tab
                icon={<UserIcon selected={currentTab === 0} />}
                iconPosition="start"
                label="Individual"
                value={0}
              />
              <Tab
                icon={
                  <BusinessIcon
                    selected={currentTab === 1}
                    width="20px"
                    height="20px"
                  />
                }
                iconPosition="start"
                label="Business"
                value={1}
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={currentTab} index={0}>
            <form onSubmit={handleCustomerSubmit(onSubmit)}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    width: isLargeScreen
                      ? "100%"
                      : isMiniDesktopScreen
                        ? "55%"
                        : "65%",
                  }}
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleAccordionChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Customer Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.firstName"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "First name is required",
                                pattern: {
                                  value: /^[A-Za-z]+$/i,
                                  message:
                                    "First name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="First Name"
                                  placeholder="Enter first name"
                                  error={
                                    customerErrors.customer?.firstName
                                      ? customerErrors.customer.firstName
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.lastName"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Last name is required",
                                pattern: {
                                  value: /^[A-Za-z]+$/i,
                                  message:
                                    "Last name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="Last Name"
                                  placeholder="Enter last name"
                                  error={
                                    customerErrors.customer?.lastName
                                      ? customerErrors.customer.lastName.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="customer.address1"
                              control={customerControl}
                              defaultValue=""
                              rules={{ required: "Address Line 1 is required" }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<AddressIcon />}
                                  label="Address Line 1 (Street Address)"
                                  placeholder="Enter address"
                                  error={
                                    customerErrors.customer?.address1
                                      ? customerErrors.customer.address1.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="customer.address2"
                              control={customerControl}
                              defaultValue=""
                              rules={{ required: "Address Line 2 is required" }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<StoreIcon />}
                                  label="Address Line 2 (Post office number, Suite Number, etc)"
                                  placeholder="Enter post office number, suite number, etc."
                                  error={
                                    customerErrors.customer?.address2
                                      ? customerErrors.customer.address2.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={4}>
                            <Controller
                              name="customer.state"
                              control={customerControl}
                              defaultValue=""
                              rules={{ required: "State is required" }}
                              render={({ field }) => {
                                const stateOptions = countries;
                                const selectedOption = stateOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={stateOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                      onStateSelect(newValue.value);
                                    }}
                                    label="State"
                                    placeholder="Select State"
                                    fullWidth={true}
                                    icon={<Homework />}
                                    error={
                                      customerErrors.customer?.state
                                        ? customerErrors.customer?.state.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={4}>
                            <Controller
                              name="customer.city"
                              control={customerControl}
                              defaultValue=""
                              rules={{ required: "City is required" }}
                              render={({ field }) => {
                                const cityOptions = cityList;
                                const selectedOption = cityOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={cityOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                    }}
                                    icon={<Location />}
                                    label="City"
                                    placeholder="Select City"
                                    error={
                                      customerErrors.customer?.city
                                        ? customerErrors.customer?.city.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={4}>
                            <Controller
                              name="customer.postalCode"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Postal Code is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Only numbers are allowed for Postal Code",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<MyLocation />}
                                  label="Postal Code"
                                  placeholder="Enter postal code"
                                  error={
                                    customerErrors.customer?.postalCode
                                      ? customerErrors.customer?.postalCode
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleAccordionChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Contact Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <FormLabel
                              component="legend"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#374151",
                              }}
                            >
                              Select Phone Type
                            </FormLabel>
                            <Controller
                              name="customer.selectedPhone"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Please select a phone type",
                              }}
                              render={({ field }) => (
                                <>
                                  <RadioGroup {...field} row>
                                    <FormControlLabel
                                      value="Home Phone"
                                      control={<Radio />}
                                      label="Home Phone"
                                    />
                                    <FormControlLabel
                                      value="Cell Phone"
                                      control={<Radio />}
                                      label="Cell Phone"
                                    />
                                    <FormControlLabel
                                      value="Business Phone"
                                      control={<Radio />}
                                      label="Business Phone"
                                    />
                                    <FormControlLabel
                                      value="Evening Phone"
                                      control={<Radio />}
                                      label="Evening Phone"
                                    />
                                  </RadioGroup>
                                  {customerErrors.customer?.selectedPhone && (
                                    <Typography
                                      style={{
                                        color: "#DF6060",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {
                                        customerErrors.customer?.selectedPhone
                                          .message
                                      }
                                    </Typography>
                                  )}
                                </>
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="customer.phoneNumber"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Phone Number is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Only numbers are allowed for Phone Number",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  label="Phone Number"
                                  icon={<PhoneIcon />}
                                  placeholder="Enter phone number"
                                  error={
                                    customerErrors.customer?.phoneNumber
                                      ? customerErrors.customer?.phoneNumber
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="customer.email"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Enter a valid email address",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  label="Email Address"
                                  icon={<EmailIcon />}
                                  placeholder="Enter Email"
                                  error={
                                    customerErrors.customer?.email
                                      ? customerErrors.customer?.email.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  {/* {isLargeScreen && ( */}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#fff",
                          color: "#000000",
                          border: "1px solid black",
                          marginRight: "10px",
                        }}
                        onClick={handleCustomerCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          background: isCustomerValid ? "#1F3A91" : "#BCBCBD",
                          color: "#ffffff !important",
                        }}
                        onClick={handleCustomerSubmit(onSubmit)}
                        disabled={!isCustomerValid}
                      >
                        Add Customer
                      </Button>
                    </Box>
                  {/* )} */}
                </Box>
                {!isLargeScreen && (
                  <Box
                    sx={{
                      width: "1%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        border: "1px dashed #D5E1F9",
                        height: "100%",
                      }}
                    />
                  </Box>
                )}
                {!isLargeScreen && (
                  <Box sx={{ width: isMiniDesktopScreen ? "42%" : "32%" }}>
                    <Box
                      className="accordianbox"
                      sx={{ background: "#F7FAFF !important" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "#374151",
                          fontSize: "16px",
                          marginBottom: "10px",
                        }}
                      >
                        {"Customer OverView"}
                      </Typography>
                      <Box
                        sx={{
                          background: "#fff",
                          padding: "20px",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!hasCustomerDetails && (
                            <Box
                              sx={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <NoDeatilsInformation />
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#303132",
                                  fontWeight: "600",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                No details added yet!
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#6B7280",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                Get started by adding details to onboard a new
                                client.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {hasCustomerDetails && (
                          <>
                            {expanded === "panel1" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: customerErrors.customer?.firstName
                                        ? "-"
                                        : watchCustomer("customer.firstName") ||
                                        "-",
                                      done: !!watchCustomer("customer.firstName"),
                                    },
                                    {
                                      label: "Last Name",
                                      value: customerErrors.customer?.lastName
                                        ? "-"
                                        : watchCustomer("customer.lastName") ||
                                        "-",
                                      done: !!watchCustomer("customer.lastName"),
                                    },
                                    {
                                      label: "Address",
                                      value: customerErrors.customer?.address1
                                        ? "-"
                                        : watchCustomer("customer.address1") ||
                                        "-",
                                    },
                                    {
                                      label: "Postal Office Number",
                                      value: customerErrors.customer?.address2
                                        ? "-"
                                        : watchCustomer("customer.address2") ||
                                        "-",
                                    },
                                    {
                                      label: "State",
                                      value: customerErrors.customer?.state
                                        ? "-"
                                        : watchCustomer("customer.state") ||
                                        "-",
                                    },
                                    {
                                      label: "City",
                                      value: customerErrors.customer?.city
                                        ? "-"
                                        : watchCustomer("customer.city") || "-",
                                    },
                                    {
                                      label: "Postal Code",
                                      value: customerErrors.customer?.postalCode
                                        ? "-"
                                        : watchCustomer(
                                          "customer.postalCode"
                                        ) || "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={"-"}
                                  section="Contact Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel2" && (
                              <>
                                <CustomerOverview
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  section="Customer Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: customerErrors.customer?.firstName
                                        ? "-"
                                        : watchCustomer("customer.firstName") ||
                                        "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: customerErrors.customer?.lastName
                                        ? "-"
                                        : watchCustomer("customer.lastName") ||
                                        "-",
                                    },
                                    {
                                      label: "Address",
                                      value: customerErrors.customer?.address1
                                        ? "-"
                                        : watchCustomer("customer.address1") ||
                                        "-",
                                    },
                                    {
                                      label: "Postal Office Number",
                                      value: customerErrors.customer?.address2
                                        ? "-"
                                        : watchCustomer("customer.address2") ||
                                        "-",
                                    },
                                    {
                                      label: "State",
                                      value: customerErrors.customer?.state
                                        ? "-"
                                        : watchCustomer("customer.state") ||
                                        "-",
                                    },
                                    {
                                      label: "City",
                                      value: customerErrors.customer?.city
                                        ? "-"
                                        : watchCustomer("customer.city") || "-",
                                    },
                                    {
                                      label: "Postal Code",
                                      value: customerErrors.customer?.postalCode
                                        ? "-"
                                        : watchCustomer(
                                          "customer.postalCode"
                                        ) || "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  sectionDetails={
                                    watchCustomer("customer.selectedPhone") ||
                                    "-"
                                  }
                                  src={contactInformation}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "Phone Number",
                                      value: customerErrors.customer
                                        ?.phoneNumber
                                        ? "-"
                                        : watchCustomer(
                                          "customer.phoneNumber"
                                        ) || "-",
                                    },
                                    {
                                      label: "Email",
                                      value: customerErrors.customer?.email
                                        ? "-"
                                        : watchCustomer("customer.email") ||
                                        "-",
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    {/* <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#fff",
                          color: "#000000",
                          border: "1px solid black",
                          marginRight: "10px",
                        }}
                        onClick={handleCustomerCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          background: isCustomerValid ? "#1F3A91" : "#BCBCBD",
                          color: "#ffffff !important",
                        }}
                        onClick={handleCustomerSubmit(onSubmit)}
                        disabled={!isCustomerValid}
                      >
                        Add Customer
                      </Button>
                    </Box> */}
                  </Box>
                )}
              </Box>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={1}>
            <form onSubmit={handleBusinessSubmit(onSubmit)}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    width: isLargeScreen
                      ? "100%"
                      : isMiniDesktopScreen
                        ? "55%"
                        : "65%",
                  }}
                >
                  <Accordion
                    expanded={expandedBusiness === "businessPanel1"}
                    onChange={handleBusinessAccordionChange("businessPanel1")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Customer Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.businessName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Buiness name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Buiness name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<Homework />}
                                  label="Business Name"
                                  placeholder="Enter business name"
                                  error={
                                    businessErrors.business?.businessName
                                      ? businessErrors.business.businessName
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.address1"
                              control={businessControl}
                              defaultValue=""
                              rules={{ required: "Address Line 1 is required" }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<AddressIcon />}
                                  label="Address Line 1 (Street Address)"
                                  placeholder="Enter address"
                                  error={
                                    businessErrors.business?.address1
                                      ? businessErrors.business.address1.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.address2"
                              control={businessControl}
                              defaultValue=""
                              rules={{ required: "Address Line 2 is required" }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<StoreIcon />}
                                  label="Address Line 2 (Post office number, Suite Number, etc)"
                                  placeholder="Enter post office number, suite number, etc."
                                  error={
                                    businessErrors.business?.address2
                                      ? businessErrors.business.address2.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={4}>
                            <Controller
                              name="business.state"
                              control={businessControl}
                              defaultValue=""
                              rules={{ required: "State is required" }}
                              render={({ field }) => {
                                const stateOptions = countries;
                                const selectedOption = stateOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={stateOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                      onStateSelect(newValue.value);
                                    }}
                                    label="State"
                                    placeholder="Select State"
                                    fullWidth={true}
                                    icon={<Homework />}
                                    error={
                                      businessErrors.business?.state
                                        ? businessErrors.business.state.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={4}>
                            <Controller
                              name="business.city"
                              control={businessControl}
                              defaultValue=""
                              rules={{ required: "City is required" }}
                              render={({ field }) => {
                                const cityOptions = cityList;
                                const selectedOption = cityOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={cityOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                    }}
                                    icon={<Location />}
                                    label="City"
                                    placeholder="Select City"
                                    error={
                                      businessErrors.business?.city
                                        ? businessErrors.business.city.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={4}>
                            <Controller
                              name="business.postalCode"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Postal Code is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Only numbers are allowed for Postal Code",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<MyLocation />}
                                  label="Postal Code"
                                  placeholder="Enter postal code"
                                  error={
                                    businessErrors.business?.postalCode
                                      ? businessErrors.business?.postalCode
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedBusiness === "businessPanel2"}
                    onChange={handleBusinessAccordionChange("businessPanel2")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Contact Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.firstName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "First name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "First name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="First Name"
                                  placeholder="Enter first name"
                                  error={
                                    businessErrors.business?.firstName
                                      ? businessErrors.business.firstName
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.lastName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Last name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Last name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="Last Name"
                                  placeholder="Enter last name"
                                  error={
                                    businessErrors.business?.lastName
                                      ? businessErrors.business.lastName.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <FormLabel
                              component="legend"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#374151",
                              }}
                            >
                              Select Phone Type
                            </FormLabel>
                            <Controller
                              name="business.selectedPhone"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Please select a phone type",
                              }}
                              render={({ field }) => (
                                <>
                                  <RadioGroup {...field} row>
                                    <FormControlLabel
                                      value="Home Phone"
                                      control={<Radio />}
                                      label="Home Phone"
                                    />
                                    <FormControlLabel
                                      value="Cell Phone"
                                      control={<Radio />}
                                      label="Cell Phone"
                                    />
                                    <FormControlLabel
                                      value="Business Phone"
                                      control={<Radio />}
                                      label="Business Phone"
                                    />
                                    <FormControlLabel
                                      value="Evening Phone"
                                      control={<Radio />}
                                      label="Evening Phone"
                                    />
                                  </RadioGroup>
                                  {businessErrors.business?.selectedPhone && (
                                    <Typography
                                      style={{
                                        color: "#DF6060",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {
                                        businessErrors.business?.selectedPhone
                                          .message
                                      }
                                    </Typography>
                                  )}
                                </>
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="business.phoneNumber"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Phone Number is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Only numbers are allowed for Phone Number",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  label="Phone Number"
                                  icon={<PhoneIcon />}
                                  placeholder="Enter phone number"
                                  error={
                                    businessErrors.business?.phoneNumber
                                      ? businessErrors.business?.phoneNumber
                                        .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="business.email"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Enter a valid email address",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  label="Email Address"
                                  icon={<EmailIcon />}
                                  placeholder="Enter Email"
                                  error={
                                    businessErrors.business?.email
                                      ? businessErrors.business?.email.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  {/* {isLargeScreen && ( */}
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#fff",
                          color: "#000000",
                          border: "1px solid black",
                          marginRight: "10px",
                        }}
                        onClick={handleBusinessCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          background: isBusinessValid ? "#1F3A91" : "#BCBCBD",
                          color: "#ffffff !important",
                        }}
                        onClick={handleBusinessSubmit(onSubmit)}
                        disabled={!isBusinessValid}
                      >
                        Add Customer
                      </Button>
                    </Box>
                  {/* )} */}
                </Box>
                {!isLargeScreen && (
                  <Box
                    sx={{
                      width: "1%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        border: "1px dashed #D5E1F9",
                        height: "100%",
                      }}
                    />
                  </Box>
                )}
                {!isLargeScreen && (
                  <Box sx={{ width: isMiniDesktopScreen ? "42%" : "32%" }}>
                    <Box
                      className="accordianbox"
                      sx={{ background: "#F7FAFF !important" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "#374151",
                          fontSize: "16px",
                          marginBottom: "10px",
                        }}
                      >
                        {"Customer OverView"}
                      </Typography>
                      <Box
                        sx={{
                          background: "#fff",
                          padding: "20px",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!hasBusinessDetails && (
                            <Box
                              sx={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <NoDeatilsInformation />
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#303132",
                                  fontWeight: "600",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                No details added yet!
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#6B7280",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                Get started by adding details to onboard a new
                                client.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {hasBusinessDetails && (
                          <>
                            {expandedBusiness === "businessPanel1" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Business"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Business Name",
                                      value: businessErrors.business
                                        ?.businessName
                                        ? "-"
                                        : watchBusiness(
                                          "business.businessName"
                                        ) || "-",
                                    },
                                    {
                                      label: "Address",
                                      value: businessErrors.business?.address1
                                        ? "-"
                                        : watchBusiness("business.address1") ||
                                        "-",
                                    },
                                    {
                                      label: "Postal Office Number",
                                      value: businessErrors.business?.address2
                                        ? "-"
                                        : watchBusiness("business.address2") ||
                                        "-",
                                    },
                                    {
                                      label: "State",
                                      value: businessErrors.business?.state
                                        ? "-"
                                        : watchBusiness("business.state") ||
                                        "-",
                                    },
                                    {
                                      label: "City",
                                      value: businessErrors.business?.city
                                        ? "-"
                                        : watchBusiness("business.city") || "-",
                                    },
                                    {
                                      label: "Postal Code",
                                      value: businessErrors.business?.postalCode
                                        ? "-"
                                        : watchBusiness(
                                          "business.postalCode"
                                        ) || "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={"-"}
                                  section="Contact Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expandedBusiness === "businessPanel2" && (
                              <>
                                <CustomerOverview
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  section="Customer Information"
                                  details={[
                                    {
                                      label: "Business Name",
                                      value: businessErrors.business
                                        ?.businessName
                                        ? "-"
                                        : watchBusiness(
                                          "business.businessName"
                                        ) || "-",
                                    },
                                    {
                                      label: "Address",
                                      value: businessErrors.business?.address1
                                        ? "-"
                                        : watchBusiness("business.address1") ||
                                        "-",
                                    },
                                    {
                                      label: "Postal Office Number",
                                      value: businessErrors.business?.address2
                                        ? "-"
                                        : watchBusiness("business.address2") ||
                                        "-",
                                    },
                                    {
                                      label: "State",
                                      value: businessErrors.business?.state
                                        ? "-"
                                        : watchBusiness("business.state") ||
                                        "-",
                                    },
                                    {
                                      label: "City",
                                      value: businessErrors.business?.city
                                        ? "-"
                                        : watchBusiness("business.city") || "-",
                                    },
                                    {
                                      label: "Postal Code",
                                      value: businessErrors.business?.postalCode
                                        ? "-"
                                        : watchBusiness(
                                          "business.postalCode"
                                        ) || "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={
                                    watchBusiness("business.selectedPhone") ||
                                    "-"
                                  }
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: businessErrors.business?.firstName
                                        ? "-"
                                        : watchBusiness("business.firstName") ||
                                        "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: businessErrors.business?.lastName
                                        ? "-"
                                        : watchBusiness("business.lastName") ||
                                        "-",
                                    },
                                    {
                                      label: "Phone Number",
                                      value: businessErrors.business
                                        ?.phoneNumber
                                        ? "-"
                                        : watchBusiness(
                                          "business.phoneNumber"
                                        ) || "-",
                                    },
                                    {
                                      label: "Email",
                                      value: businessErrors.business?.email
                                        ? "-"
                                        : watchBusiness("business.email") ||
                                        "-",
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    {/* <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#fff",
                          color: "#000000",
                          border: "1px solid black",
                          marginRight: "10px",
                        }}
                        onClick={handleBusinessCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          background: isBusinessValid ? "#1F3A91" : "#BCBCBD",
                          color: "#ffffff !important",
                        }}
                        onClick={handleBusinessSubmit(onSubmit)}
                        disabled={!isBusinessValid}
                      >
                        Add Customer
                      </Button>
                    </Box> */}
                  </Box>
                )}
              </Box>
            </form>
          </CustomTabPanel>
        </Box>
      </Panel>
    </>
  );
};

export default AddNewCustomer;
