import './App.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { ToastProvider } from './context/ToastNotification';
import { NotificationProvider } from './context/NotificationContext';
import { RouterProvider } from 'react-router-dom';
import AppRouter from './routes';
import { CssBaseline } from '@mui/material';
import { AuthContext } from './context/AuthContext';
import ScrollTop from './components/ScrollTop';
import CustomThemeProvider from './theme';
import AppContextProvider from './context/AppContext';

function App() {
  const authInfo = JSON.parse(localStorage.getItem('user') || '{}');


  return (
    <AppContextProvider>
      <CustomThemeProvider>
        <CssBaseline enableColorScheme />
        <AuthContext.Provider
          value={authInfo}
        >
          <NotificationProvider>
            <ToastProvider>
              <ScrollTop>
                <RouterProvider router={AppRouter} />
              </ScrollTop>
            </ToastProvider>
          </NotificationProvider>
        </AuthContext.Provider>
      </CustomThemeProvider>
    </AppContextProvider>
  );
}

export default App;
