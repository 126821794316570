import { Theme, createTheme } from "@mui/material/styles";

import { ReactComponent as CheckBoxUnSelectIcon } from "../assets/media/svg/check box - unselected.svg";
import { ReactComponent as CheckBoxSelectIcon } from "../assets/media/svg/checkbox - selected.svg";
import { color } from "html2canvas/dist/types/css/types/color";

export const shades = {
  primary: {
    main: "#1d3db0",
    200: "#1f3a91",
    300: "#1d3587",
    400: "#192f76",
    500: "#152865",
    600: "#122154",
    700: "#oe1b43",
    800: "#0b1432",
    900: "#070d22",
  },
  tint: {},
  secondary: {
    main: "#001f3d",
    600: "#003d7a",
    700: "#003366",
    800: "#002952",
    900: "#001f3d",
  },
};

export const shadows = {
  button: {
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
  },
};

const ThemeV1: Theme = createTheme({
  palette: {
    background: {
      default: "#eff3f8",
    },
    primary: {
      main: shades.primary["200"],
      dark: "#212121",
      light: "#1f3a91",
      "300": "#1d3587",
      "400": "#192f76",
      "500": "#152865",
      "600": "#122154",
      "700": "#oe1b43",
      "800": "#0b1432",
      "900": "#070d22",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#6B7280",
    },
    // error: {

    // },
    // info: {

    // },
    // success: {
    //     // main: ''
    // },
    // warning: {

    // }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    button: {
      textTransform: "none",
    },
    // h1: {
    //     fontSize: '32px',
    //     lineHeightStep: 'auto'
    // },
    // h2: {
    //     fontSize: '28px',
    //     color: '#374151'
    // },
    // h3: {
    //     fontSize: '24px',
    //     color: '#374151'
    // },
    // h4: {
    //     fontSize: '20px',
    //     color: '#374151'
    // },
    // h5: {
    //     fontSize: '28px',
    //     lineHeightStep: 'auto',
    //     color: '#374151'
    // },
    // h6: {
    //     // fontFamily: 'OpenSans-Regular'
    //     color: '#374151',
    // },
    // subtitle1: {
    //     fontSize: '18px',
    //     color: '#374151'
    // },
    // subtitle2: {
    //     fontSize: '16px',
    //     color: '#374151'
    // },
    // body1: {
    //     fontSize: '28px',
    //     color: '#374151'
    // },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "blue",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // color: '#374151',
          // variants: {
          // }
        },
      },
      defaultProps: {},
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: shades.primary["200"],
          borderRadius: "0px 15px 0px 0px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#ffffff", 
          color: "#374151",
          maxWidth:"192px",
          textWrap:"wrap",
          fontSize: "12px",
          fontWeight:"500",
          textAlign:"center",
          borderRadius: "8px",
          padding: "8px 12px",
          boxShadow: "0px 2px 8px rgba(1, 87, 184, 0.2)",
          border: "0.5px solid #D5E1F9",
        },
        arrow: {
          color: "#ffffff",          
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          color: "primary",
          height: "50px !important",
          "& .MuiButtonGroup-grouped": {
            "border-color": "grey",
          },
          "&:hover": {
            boxShadow: "0px 0px 0px 2.8px #bfdbfa",
          },
          "&:focus .Mui-focused": {
            border: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            // boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
          },
          // '& span': {
          //     color: 'white'
          // }
        },
        outlinedPrimary: {
          // color: '#1976d2', // Change text color for outlined primary button
          // borderColor: '#1976d2', // Border color (optional)
          "& span": {
            color: "black",
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // backgroundColor: shades.primary['200'],
          "&.primary > svg span": {
            color: shades.primary["200"],
          },
          "&.primary > p": {
            color: "white",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid #e5e7eb",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableHead": {
            background: "#FCFCFD",
            border: "1px solid #D5E1F980 !important",
            borderLeft: "none",
            borderRight: "none",
          },
        },
      },
    },
    MuiTableSortLabel:{
      styleOverrides:{
        root:{
         "&.MuiTableSortLabel-root":{
          "&.Mui-active":{
            color:"#6B7280"
          }
         }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // display:"inline",
          "&.MuiTableRow-hover:hover": {
            cursor: "pointer",
            // background: 'red'
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "50px",
          border: "none",
          borderRadius: "10px",
          // borderColor: "#eaf0fc",
          "& :hover": {
            BorderColor: "red",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#411882", // Hover border color
            // border: '50px solid',
          },
        },
      },
    },
    // MuiCheckbox: {
    //   defaultProps: {
    //     icon: <CheckBoxSelectIcon />,
    //     checkedIcon: <CheckBoxUnSelectIcon />,
    //   },
    // },
    MuiAlert: {
      styleOverrides: {
        colorError: {
          backgroundColor: "red",
        },
        colorInfo: {
          backgroundColor: "blue",
        },
        colorSuccess: {
          backgroundColor: "green",
        },
        colorWarning: {
          backgroundColor: "yellow",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          // borderRadius: '50px',
          borderRadius: "12px",
          // For oulined fields
          "$ .MuiOutlinedInput-root": {
            borderradius: "50px solid",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            // borderColor: '#1976d2', // Custom border color,
            border: "none",
            borderRadius: "10px",
          },
          // '&:hover .MuiOutlinedInput-notchedOutline': {
          //     borderColor: '#dc004e', // Hover border color
          //     border: '50px solid',
          // },
          // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //     borderColor: '#1976d2', // Focused border color
          //     border: '50px solid',
          // },

          // for normal input fields
          "& .MuiInputBase-input": {
            border: "50px solid",
          },
          "&:hover .MuiInputBase-input": {
            borderColor: "red", // Hover border color
          },
        },
        input: {
          padding: "10px", // Custom padding for input text
          color: "#333", // Text color
        },
      },
    },
  },
});

export default ThemeV1;
