import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Fragment, useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Link from "@mui/material/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../IbaReport.css";
import Panel from "../../../components/Panel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TransactionHistoryComponent from "./TransactionHistory";
import {
  GetAccountDetails,
  GetAccountSummary,
  GetFinancialReport,
} from "../../../api/IBAReport";
import { GetCustomers } from "../../../api/customerAPI";
import CustomMonthPicker from "../../../components/form/MonthPicker";
import YearPicker from "../../../components/form/YearPicker";
import IBABreadCrumbIcon from "../../../assets/IconComponents/IBABreadCrumbIcon";
import FileDownloadIcon from "../../../assets/IconComponents/FileDownloadIcon";
import BankSureLogo from "../../../assets/media/icons/BankSureIBA.png";
import SummaryAccount from "./SummaryAccount";
import EmployementIncome from "./Employement&Income";
import RecurringDebits from "./RecurringDebits";
import SaveIcon from "../../../assets/IconComponents/SaveIcon";
import MonthYearSelection from "../../../components/form/MonthYearSelection";

import MonthYearPicker from "../../../components/form/MonthYearSelection";
import CombinedMonthYearPicker from "../../../components/form/MonthYearSelection";
import PotentialLoans from "./PotentialLoans";
import LoanInstalmentAmount from "./LoanInstalmentAmount";

const IBAReportAnalysis = (props: any) => {
  const reportRef = useRef<HTMLDivElement>(null);
  const [downloadOptionsEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [downloadState, toggleDownloadLoader] = useState(false);
  const [accountSummary, setAccountSummary] = useState<any>({});
  const [financialReport, setFinancialReport] = useState<any>({});
  const [accountDetail, setAccountDetail] = useState<any>({});
  const [customerDetail, setCustomerDetail] = useState<any>({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [isLoading, toggleLoading] = useState<Boolean>(false);
  const open = Boolean(downloadOptionsEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    initPage();
  }, []);

  const initPage = async () => {
    toggleLoading(true);
    try {
      const [accountSummary, financialReport, accountDetails, customersList] =
        await Promise.all([
          GetAccountSummary({}),
          GetFinancialReport({}),
          GetAccountDetails({}),
          GetCustomers({}),
        ]);
      setAccountSummary(accountSummary);
      setFinancialReport(financialReport);
      setAccountDetail(accountDetails);
      if (customersList.length) {
        setCustomerDetail(customersList[0]);
      }
    } catch (err) {
      console.error("error in report analysis screen");
    }
    toggleLoading(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/report"}
      sx={{ width: "120px", display: "flex" }}
    >
      <IBABreadCrumbIcon />
      <Typography sx={{ marginLeft: 1, color: theme.palette.primary.main }}>
        IBA Report
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      {customerDetail.firstName ?? "Dhanush"}{" "}
      {customerDetail.lastName ?? "Reddy"} IBA Request
    </Typography>,
  ];

  const downloadPDF = () => {
    const input = reportRef.current;
    toggleDownloadLoader(true);

    if (input) {
      const tempContainer = document.createElement("div");
      tempContainer.style.width = "1400px";
      tempContainer.style.margin = "0 auto";
      tempContainer.style.position = "absolute";
      tempContainer.style.top = "-9999px";
      tempContainer.style.left = "0";

      const clonedContent = input.cloneNode(true);
      tempContainer.appendChild(clonedContent);

      document.body.appendChild(tempContainer);

      html2canvas(tempContainer).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }

        pdf.save("IBA_Report.pdf");

        toggleDownloadLoader(false);

        document.body.removeChild(tempContainer);
      });
    }
  };

  const downloadJSON = () => {
    toggleDownloadLoader(true);
    try {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(financialReport)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "IBA_Report.json";

      link.click();
    } catch (err) {
      console.error(err);
    }
    toggleDownloadLoader(false);
  };

  const convertXML = (
    data: any,
    tagName: any,
    arrayElementTag = "element",
    spaces = 0
  ) => {
    const tag = tagName
      .replace(/[^_a-zA-Z 0-9:\-\.]/g, "")
      .replace(/^([ 0-9-:\-\.]|(xml))+/i, "")
      .replace(/ +/g, "-");

    const indentSpaces = Array(spaces + 1).join(" ");

    if (data === null || data === undefined) {
      return `${indentSpaces}<${tag} />`;
    }

    const content =
      Object.prototype.toString.call(data) === "[object Array]"
        ? data
            .map((item: any) =>
              convertXML(item, arrayElementTag, arrayElementTag, spaces + 2)
            )
            .join("\n")
        : typeof data === "object"
        ? Object.keys(data)
            .map((key) => [key, data[key]])
            .map(([key, value]) =>
              convertXML(value, key, arrayElementTag, spaces + 2)
            )
            .join("\n")
        : indentSpaces +
          "  " +
          String(data).replace(/([<>&])/g, (_, $1) => {
            switch ($1) {
              case "<":
                return "&lt;";
              case ">":
                return "&gt;";
              case "&":
                return "&amp;";
              default:
                return "";
            }
          });

    const contentWithWrapper: any = `${indentSpaces}<${tag}>
                ${content}
                ${indentSpaces}</${tag}>`;

    return contentWithWrapper;
  };

  function downloadXML() {
    toggleDownloadLoader(true);
    try {
      const content = `<?xml version="1.0" encoding="utf-8"?><!DOCTYPE root>
            ${convertXML(financialReport, "root")}
            `;

      const dataStr =
        "data:text/application/xml;charset=utf-8," +
        encodeURIComponent(content);
      let downloadData: any = document.getElementById("downloadData");

      const link = document.createElement("a");
      link.href = dataStr;
      link.download = "IBA_Report.xml";

      link.click();

      // downloadData.setAttribute("href", dataStr);
      // downloadData.setAttribute("download", "xmlData.xml");
      // downloadData.click();
    } catch (err) {
      console.error(err);
    }
    toggleDownloadLoader(false);
  }

  const data = [
    { label: "MerchID", value: "001233" },
    { label: "Transaction", value: "P39866" },
    { label: "Ordered", value: "7/15/24" },
    { label: "Period", value: "4/15/24 - 7/15/24" },
  ];
  return (
    <Box>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box>
          <Typography
            sx={{ color: "#303132", fontSize: "20px", fontWeight: "700" }}
          >
            {customerDetail.firstName ?? "Dhanush"}{" "}
            {customerDetail.lastName ?? "Reddy"}
          </Typography>
          <Typography
            sx={{ color: "#6B7280", fontSize: "18px", fontWeight: "400" }}
          >
            IBA Report Details
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "row", sm: "row" },
            }}
          >
            <Box sx={{ maxWidth: { sm: "50%", md: "240px", lg: "150px" } }}>
              <CombinedMonthYearPicker />
            </Box>
            <Box sx={{ maxWidth: { sm: "50%", md: "240px", lg: "150px" } }}>
              <CombinedMonthYearPicker placeholder="To" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "row", sm: "row" },
            }}
          >
            <Button
              size="large"
              sx={{
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "700",
                border: "1px solid #1F3A91",
                color: "#1F3A91",
                height: "48px",
                width: { md: "180px", lg: "150px" },
              }}
              startIcon={<SaveIcon />}
            >
              Save Report
            </Button>
            <Button
              size="large"
              sx={{
                height: "48px",
                background: "#1F3A91",
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "700",
                color: "#FFFFFF",
                width: { sm: "150px", md: "180px", lg: "150px" },
              }}
              startIcon={
                downloadState ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      height: "20px",
                      width: "20px",
                      color: "#FFFFFF !important",
                    }}
                  />
                ) : (
                  <FileDownloadIcon fill="#FFFFFF" />
                )
              }
              endIcon={
                open ? (
                  <KeyboardArrowUpIcon sx={{ color: "white" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "white" }} />
                )
              }
              onClick={handleClick}
            >
              <Typography variant="button">Download</Typography>
            </Button>
          </Box>
          <Menu
            id="download-options"
            anchorEl={downloadOptionsEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                downloadPDF();
                handleClose();
              }}
            >
              <Typography>
                PDF <br />
                <Typography variant="caption">
                  Download in PDF Format
                </Typography>
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                downloadXML();
                handleClose();
              }}
            >
              <Typography>
                XML <br />
                <Typography variant="caption">
                  Download in XML Format
                </Typography>
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                downloadJSON();
                handleClose();
              }}
            >
              <Typography>
                JSON <br />
                <Typography variant="caption">
                  Download in JSON Format
                </Typography>
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box ref={reportRef} sx={{ width: "100%" }}>
        <Panel>
          <Box sx={{ padding: "20px" }}>
            <Grid size={12} rowSpacing={2} rowGap={5}>
              <Panel
                sx={{
                  backgroundColor: theme?.palette?.primary?.main,
                  padding: 2,
                  color: "white",
                  borderRadius: 0,
                }}
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ gap: 2 }}
                >
                  {data?.map((item, index) => (
                    <Fragment key={index}>
                      <Box
                        flex={{
                          sm: "0 0 10%",
                          md: "0 0 18%",
                          lg: "0 0 17%",
                          xl: "0 0 20%",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#D9D9D9",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {item.label}:&nbsp;
                          <Typography
                            component="span"
                            sx={{
                              color: "#FFFFFF",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {item.value}
                          </Typography>
                        </Typography>
                      </Box>
                      {isSmallScreen && index < data.length - 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mx: 1,
                          }}
                        >
                          <FiberManualRecordIcon
                            sx={{ height: 10, color: "#FFFFFF" }}
                          />
                        </Box>
                      )}
                    </Fragment>
                  ))}
                </Box>
              </Panel>
            </Grid>
            <Grid size={12} sx={{ marginTop: 3, marginBottom: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between",flexDirection:{xs:"column",sm:"row"} }}>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    {customerDetail.firstName ?? "Dhanush"}{" "}
                    {customerDetail.lastName ?? "Reddy"}
                    <Typography
                      component={"span"}
                      sx={{
                        backgroundColor: "rgba(47, 142, 38, 0.08)",
                        padding: "5px",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                    >
                      Score:{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          color: "#2F8E26",
                          fontSize: "24px",
                          fontWeight: 700,
                        }}
                      >
                        720
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#797979",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    {[
                      customerDetail.addressLine2,
                      customerDetail.city,
                      customerDetail.state,
                      customerDetail.postalCode,
                    ]
                      .filter((e) => e)
                      .join(" ,") || "1563 S Willow Rd. Birmingham NC 23971"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#797979",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Phone Number:{" "}
                    <Typography
                      component={"span"}
                      sx={{
                        color: "#333333",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {customerDetail.phoneNumber ?? "877-455-8989"}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#797979",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Email:{" "}
                    <Typography
                      component={"span"}
                      sx={{
                        color: "#333333",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {customerDetail.email || "tony2023@gmail.com"}
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Box
                    component={"img"}
                    src={BankSureLogo}
                    width={{sm:"210px",md:"230px"}}
                    height={"125px"}
                  />
                </Box>
              </Box>
            </Grid>
            <SummaryAccount financialReport={financialReport} />
           <LoanInstalmentAmount />
            <EmployementIncome />
            <PotentialLoans />
            <RecurringDebits />
            <TransactionHistoryComponent />
          </Box>
        </Panel>
      </Box>
    </Box>
  );
};

export default IBAReportAnalysis;
