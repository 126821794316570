import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

/**
 * @description Create an axios instance with base domain. if base domain is not exist then use current domain
 */
const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || ''
});

/**
 * @description Add intercepting actions for the http requests
 */
AxiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.withCredentials = true; // attach cookie to the every request
    return config;
}, (error) => {
    Promise.reject(error);
});

/**
 * @description Intercept Http responses
 */
AxiosInstance.interceptors.response.use((response: AxiosResponse) => {
    return response.data || response;
}, (err) => {
    // Handle Unauthorized error
    if (!err.response || err.response.status === 401) {
        // If user is logged is not logged in then redirecto login page with current url to retain the user state
        const currentLocation = window.location.pathname;
        let toUrl = '/auth/login'
        if (currentLocation === toUrl) {
            throw err;
        }
        if (currentLocation && currentLocation !== '/') {
            toUrl += `?redirectUrl=${currentLocation}`;
        }
        window.location.href = toUrl;
    }
    throw err;
});

export default AxiosInstance;
