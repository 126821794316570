import AxiosInstance from './http';

export async function Login(username: string, password: string) {
    try {
        const response = await AxiosInstance.post('/authentication/login', {
            email: username,
            password
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

export async function Register(userObject: any) {
    try {
        const response = await AxiosInstance.post('/authentication/register', userObject);

        return response;
    } catch (err) {
        console.error('err:', err);
        throw err;
    }
};

export async function ForgotPassword(email: string) {
    try {
        const response = await AxiosInstance.post('/authentication/fogot-password', { username: email });

        return response;
    } catch (err) {
        console.error('err');
        throw err;
    }
};

export async function LogOut() {
    try {
        const response = await AxiosInstance.post('/authentication/logout', {});

        return response;
    } catch (err) {
        console.error('err');
        throw err;
    }
};




