import { ReactNode } from 'react';
import { Grid } from '@mui/material';

interface FormInputRowProps {
  children: ReactNode;
}

export const FormInputRow = ({ children }: FormInputRowProps) => {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
};

interface FormInputColProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children: ReactNode;
}

export const FormInputCol = ({
  xs = 12,
  sm = 12,
  md = 6,
  lg = 6,
  xl = 6,
  children,
}: FormInputColProps) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
};
