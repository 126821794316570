import { Box, Breadcrumbs, Button, Divider, FormControlLabel, Grid2 as Grid, MenuItem, Select, Typography } from '@mui/material';
import Panel from '../../components/Panel';
import { Link as RouterLink } from 'react-router-dom';


import Link from '@mui/material/Link';

import PieChartIcon from '@mui/icons-material/PieChart';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const IBARequest = (props: any) => {

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={RouterLink}
            to={'/report'} sx={{ width: '120px', display: 'flex' }}
        >
            <PieChartIcon sx={{ marginRight: 1 }} />
            <Typography>
                IBA Report
            </Typography>
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            New IBA Request
        </Typography>,
    ];

    return <Grid container size={12} rowSpacing={4} rowGap={4}>
        <Grid size={12}>
            <Panel sx={{ width: '100%', padding: 5, height: '70vh' }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Panel>
        </Grid>
        <Panel sx={{ width: '100%', padding: 5, height: '70vh' }}>
            <Grid size={12} container>
                <Grid size={10}>
                    <Typography >
                        Instance Bank Verification Request
                    </Typography>
                </Grid>
                <Grid size={2}>
                    <Button>
                        Send Request
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid size={12} container sx={{ textAlign: 'center' }} justifyContent={'center'} spacing={4}>
                {/* <Box>
                    <Typography>
                        Customer
                    </Typography>
                </Box> */}

                {/* <Grid size={12} justifySelf={'center'}>
                    <Typography>
                        Customer
                    </Typography>
                </Grid> */}
                <Grid size={7} justifySelf={'center'} sx={{ marginTop: 5 }}>
                    <Typography variant='h6' textAlign={'left'}>
                        Customer
                    </Typography>
                </Grid>
                <Grid size={7} justifySelf={'center'}>
                    <Typography textAlign={'left'}>
                        Select customer
                    </Typography>
                    <Select fullWidth placeholder='Select Customer'>
                        <MenuItem value="1">Customer 1</MenuItem>
                        <MenuItem value="2">Customer 2</MenuItem>
                        <MenuItem value="3">Customer 3</MenuItem>
                        <MenuItem value="4">Customer 4</MenuItem>
                    </Select>
                    <Typography textAlign={'right'} sx={{ marginTop: 2.5 }}>
                        Note:  Customer does not exist in database? <RouterLink to='#'>Create new Customer</RouterLink>
                    </Typography>
                </Grid>
            </Grid>
        </Panel>
    </Grid>
};

export default IBARequest;