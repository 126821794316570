
function AddressIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 9.5835V10.0835H12.5H17V17.4168H3V6.75016H7.5H8V6.25016V4.7906L8.66667 4.12394V4.5835V6.25016V6.75016H9.16667H10.8333H11.3333V6.25016V4.5835V4.12394L12 4.7906V9.5835ZM8.70711 4.0835L10 2.7906L11.2929 4.0835H10.8333H9.16667H8.70711ZM5.83333 16.7502H6.33333V16.2502V14.5835V14.0835H5.83333H4.16667H3.66667V14.5835V16.2502V16.7502H4.16667H5.83333ZM5.83333 13.4168H6.33333V12.9168V11.2502V10.7502H5.83333H4.16667H3.66667V11.2502V12.9168V13.4168H4.16667H5.83333ZM5.83333 10.0835H6.33333V9.5835V7.91683V7.41683H5.83333H4.16667H3.66667V7.91683V9.5835V10.0835H4.16667H5.83333ZM10.8333 16.7502H11.3333V16.2502V14.5835V14.0835H10.8333H9.16667H8.66667V14.5835V16.2502V16.7502H9.16667H10.8333ZM10.8333 13.4168H11.3333V12.9168V11.2502V10.7502H10.8333H9.16667H8.66667V11.2502V12.9168V13.4168H9.16667H10.8333ZM10.8333 10.0835H11.3333V9.5835V7.91683V7.41683H10.8333H9.16667H8.66667V7.91683V9.5835V10.0835H9.16667H10.8333ZM15.8333 16.7502H16.3333V16.2502V14.5835V14.0835H15.8333H14.1667H13.6667V14.5835V16.2502V16.7502H14.1667H15.8333ZM15.8333 13.4168H16.3333V12.9168V11.2502V10.7502H15.8333H14.1667H13.6667V11.2502V12.9168V13.4168H14.1667H15.8333Z" fill="#6B7280" stroke="#6B7280"/>
</svg>

  )
}

export default AddressIcon