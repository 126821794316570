import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Typography, FormControlLabel, RadioGroup, FormLabel, Radio } from "@mui/material";
import profileBackground from "../../assets/media/icons/profileBackground.png";
import { FormInputCol, FormInputRow } from "../../components/form/FormRolColInput";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import Homework from "../../assets/IconComponents/Homework";
import Location from "../../assets/IconComponents/Location";
import MyLocation from "../../assets/IconComponents/MyLocation";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import BusinessIcon from "../../assets/IconComponents/BussinessIcon";
import { Controller, useForm } from "react-hook-form";
import InputComponent from "../../components/form/input";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import SelectComponent from "../../components/form/SelectComponent";
import { EditModalProps, FormData, ProfileDetailRowProps, ProfileInformationProps, SectionProps } from "./types";
import { UpdateCustomer } from '../../api/customerAPI';
import { useAlertMessage } from '../../utils/useAlertMessage';
import { useNavigate } from 'react-router-dom';
import EditModal from "../../components/form/EditModal";
import States from '../../assets/json/states.json';

const ProfileHeader = ({ userInfo }: any) => (
  <Box sx={{ position: "relative", textAlign: "center", width: "100%" }}>
    <Box component="img" src={profileBackground} alt="background" sx={{ width: "100%", height: "130px" }} />
    <Box
      sx={{
        background: "#142848",
        borderRadius: "50%",
        height: "175px",
        width: "175px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "20px solid #ffffff",
        position: "absolute",
        top: "50px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Typography sx={{ fontSize: "48px", fontWeight: "600", color: "#FFFFFF" }}>{(userInfo.firstName || '')[0]}{(userInfo.lastName || '')[0]}</Typography>
    </Box>
    <Box sx={{ marginTop: "90px", gap: "16px" }}>
      <Typography sx={{ fontWeight: "800", fontSize: "30px", color: "#1E293B",textWrap:"wrap" }}>{userInfo.firstName} {userInfo.lastName}</Typography>
      <Typography sx={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>
        E-Mail ID: {userInfo.email}
      </Typography>
    </Box>
  </Box>
);

const ProfileInformation: React.FC<ProfileInformationProps> = ({ type, userInfo }: any) => {
  const customerDetails = [
    { icon: <UserIconGray />, label: "First Name", value: userInfo.firstName },
    { icon: <UserIconGray />, label: "Last Name", value: userInfo.lastName },
  ];

  const businessDetails = [
    { icon: <BusinessIcon />, label: "Business Name", value: '' },
  ];

  const addressDetails = [
    { icon: <AddressIcon />, label: "Address Line 1", value: userInfo.addressLine1 },
    { icon: <AddressIcon />, label: "Address Line 2", value: userInfo.addressLine2 },
    { icon: <Homework />, label: "State", value: userInfo.state },
    { icon: <Location />, label: "City", value: userInfo.city },
    { icon: <MyLocation />, label: "Postal Code", value: userInfo.postalCode },
  ];

  const contactDetails = [
    { icon: <PhoneIcon />, label: "Phone Type", value: userInfo.phoneType },
    { icon: <PhoneIcon />, label: "Phone Number", value: userInfo.phoneNumber },
    { icon: <EmailIcon />, label: "Email Address", value: userInfo.email },
  ];

  return (
    <Box sx={{ paddingX: "20px" }}>
      <ProfileHeader userInfo={userInfo} />
      <Section
        type={type}
        title="Customer Information"
        userInfo={userInfo}
        details={type === "Customer" ? [...customerDetails, ...addressDetails] : [...businessDetails, ...addressDetails]}
      />
      <Section
        userInfo={userInfo}
        type={type}
        title="Contact Information"
        details={type === "Customer" ? contactDetails : [...customerDetails, ...contactDetails]} />
    </Box>
  );
};

const ProfileDetailRow: React.FC<ProfileDetailRowProps> = ({ icon, label, value }) => (
  <FormInputCol>
    <Typography sx={{ marginBottom: "20px", fontSize: "14px", fontWeight: "600", color: "#374151" }}>{label}</Typography>
    <Box sx={{ display: "flex" }}>
      <Box sx={{ marginX: "10px" }}>{icon}</Box>
      <Typography sx={{ fontSize: "16px", fontWeight: "400", color: "#6B7280" }}>{value}</Typography>
    </Box>
  </FormInputCol>
);

const countries = Object.keys(States).map(state => ({
  value: state,
  label: state
}));

const Section: React.FC<SectionProps> = ({ title, details, type, userInfo }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [cityList, setCityList] = useState<any[]>([]);

  useEffect(() => {
    if (userInfo.state) {
      onStateSelect(userInfo.state);
    }
  }, [userInfo]);

  const onStateSelect = (state: string) => {
    setCityList((States as any)[state].map((city: string) => ({
      value: city,
      label: city
    })));
  };

  const notification = useAlertMessage();

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    if (type === "Customer") {
      resetCustomerForm();
    } else {
      resetBusinessForm();
    }
  };

  const handleUpdate = () => {
    // setOpen(false);
    console.log("Update initiated, type:", type);
    if (type === "Customer") {
      handleCustomerSubmit(onSubmit)();
    } else {
      handleBusinessSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data: FormData) => {
    console.log(data)
    let customerObj;

    if (type === "Customer") {
      customerObj = {
        ...userInfo,
        id: userInfo.id,
        customerType: "Customer",
        firstName: data.customer?.firstName,
        lastName: data.customer?.lastName,
        phoneType: data.customer?.selectedPhone,
        phoneNumber: data.customer?.phoneNumber,
        email: data.customer?.email,
        addressLine1: data.customer?.address1,
        addressLine2: data.customer?.address2,
        city: data.customer?.city,
        state: data.customer?.state,
        postalCode: data.customer?.postalCode,
        userName: userInfo.userName,
      };
    } else {
      customerObj = {
        id: userInfo.id,
        customerType: "Business",
        businessName: data.business?.businessName,
        firstName: data.business?.firstName,
        lastName: data.business?.lastName,
        phoneType: data.business?.selectedPhone,
        phoneNumber: data.business?.phoneNumber,
        email: data.business?.email,
        addressLine1: data.business?.address1,
        addressLine2: data.business?.address2,
        city: data.business?.city,
        state: data.business?.state,
        postalCode: data.business?.postalCode,
      };

    }

    console.log(customerObj)
    // Uncomment and use the following code for the actual API call
    try {
      const result = await UpdateCustomer(userInfo.id, customerObj);
      if (result.statusCode === 200) {
        notification.successNotification("Success", "Customer added successfully");
        navigate("/customers");
      } else {
        notification.errorNotification("Error", result?.content || 'Error in updating customer');
      }
    } catch (err) {
      console.error("Error creating customer:", err);
    }
  };

  const {
    control: customerControl,
    handleSubmit: handleCustomerSubmit,
    formState: { errors: customerErrors },
    reset: resetCustomerForm,
  } = useForm<FormData>({ mode: "onChange" });

  const {
    control: businessControl,
    handleSubmit: handleBusinessSubmit,
    formState: { errors: businessErrors },
    reset: resetBusinessForm,
  } = useForm<FormData>({ mode: "onChange" });

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: "700", color: "#000000" }}>{title}</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "700", color: "#264D8E", textDecoration: "underline", cursor: "pointer" }} onClick={handleOpen}>EDIT DETAILS</Typography>
      </Box>
      <Box sx={{ padding: "20px", background: "#F7FAFF", margin: "20px 0 0 0" }}>
        <Box sx={{ borderRadius: "4px", background: "#FFFFFF", padding: "20px" }}>
          {details.map((detail, index) => (
            <Fragment key={index}>
              {index % 2 === 0 && (
                <FormInputRow>
                  <ProfileDetailRow icon={detail.icon} label={detail.label} value={detail.value} />
                  {details[index + 1] && (
                    <ProfileDetailRow icon={details[index + 1].icon} label={details[index + 1].label} value={details[index + 1].value} />
                  )}
                </FormInputRow>
              )}
              {index < details.length - 2 && index % 2 === 0 && (
                <Divider sx={{ width: "100%", border: "1px dashed #D5E1F9", margin: "20px 0" }} />
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
      <EditModal open={open} onClose={handleClose} title={`Edit ${title}`} handleUpdate={handleUpdate}>
        <Box sx={{ padding: "20px", border: "1px solid #DBE9FE", borderRadius: "10px" }}>
          <Box sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}>
            {type === "Customer" ? title === "Customer Information" ?
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.firstName"
                    control={customerControl}
                    defaultValue={userInfo?.firstName || ''}
                    rules={{
                      required: "First name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message:
                          "First name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="First Name"
                        placeholder="Enter first name"
                        error={
                          customerErrors.customer?.firstName
                            ? customerErrors.customer.firstName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.lastName"
                    control={customerControl}
                    defaultValue={userInfo?.lastName || ''}
                    rules={{
                      required: "Last name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message:
                          "Last name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        placeholder="Enter last name"
                        error={
                          customerErrors.customer?.lastName
                            ? customerErrors.customer.lastName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="customer.address1"
                    control={customerControl}
                    defaultValue={userInfo?.addressLine1 || ''}
                    rules={{ required: "Address Line 1 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<AddressIcon />}
                        label="Address Line 1 (Street Address)"
                        placeholder="Enter address"
                        error={
                          customerErrors.customer?.address1
                            ? customerErrors.customer.address1.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="customer.address2"
                    control={customerControl}
                    defaultValue={userInfo?.addressLine2 || ''}
                    rules={{ required: "Address Line 2 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Address Line 2 (Post office number, Suite Number, etc)"
                        placeholder="Enter post office number, suite number, etc."
                        error={
                          customerErrors.customer?.address2
                            ? customerErrors.customer.address2.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="customer.state"
                    control={customerControl}
                    defaultValue={userInfo?.state || ''}
                    rules={{ required: "State is required" }}
                    render={({ field }) => {
                      const stateOptions = countries;
                      const selectedOption = stateOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                            onStateSelect(newValue.value);
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth={true}
                          icon={<Homework />}
                          error={
                            customerErrors.customer?.state
                              ? customerErrors.customer?.state.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="customer.city"
                    control={customerControl}
                    defaultValue={userInfo?.city || ''}
                    rules={{ required: "City is required" }}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                          error={
                            customerErrors.customer?.city
                              ? customerErrors.customer?.city.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="customer.postalCode"
                    control={customerControl}
                    defaultValue={userInfo?.postalCode || ''}
                    rules={{
                      required: "Postal Code is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message:
                          "Only numbers are allowed for Postal Code",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<MyLocation />}
                        label="Postal Code"
                        placeholder="Enter postal code"
                        error={
                          customerErrors.customer?.postalCode
                            ? customerErrors.customer?.postalCode
                              .message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow> : <FormInputRow>
                <FormInputCol lg={12} md={12} xl={12}>
                  <FormLabel
                    component="legend"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#374151",
                    }}
                  >
                    Select Phone Type
                  </FormLabel>
                  <Controller
                    name="customer.selectedPhone"
                    control={customerControl}
                    defaultValue={userInfo?.phoneType || ""}
                    rules={{
                      required: "Please select a phone type",
                    }}
                    render={({ field }) => (
                      <>
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="Home Phone"
                            control={<Radio />}
                            label="Home Phone"
                          />
                          <FormControlLabel
                            value="Cell Phone"
                            control={<Radio />}
                            label="Cell Phone"
                          />
                          <FormControlLabel
                            value="Business Phone"
                            control={<Radio />}
                            label="Business Phone"
                          />
                          <FormControlLabel
                            value="Evening Phone"
                            control={<Radio />}
                            label="Evening Phone"
                          />
                        </RadioGroup>
                        {customerErrors.customer?.selectedPhone && (
                          <Typography
                            style={{
                              color: "#DF6060",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {
                              customerErrors.customer?.selectedPhone
                                .message
                            }
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="customer.phoneNumber"
                    control={customerControl}
                    defaultValue={userInfo?.phoneNumber || ""}
                    rules={{
                      required: "Phone Number is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message:
                          "Only numbers are allowed for Phone Number",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Phone Number"
                        icon={<PhoneIcon />}
                        placeholder="Enter phone number"
                        error={
                          customerErrors.customer?.phoneNumber
                            ? customerErrors.customer?.phoneNumber
                              .message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="customer.email"
                    control={customerControl}
                    defaultValue={userInfo?.email || ""}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Email Address"
                        icon={<EmailIcon />}
                        placeholder="Enter Email"
                        error={
                          customerErrors.customer?.email
                            ? customerErrors.customer?.email.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow> : title === "Customer Information" ? <FormInputRow>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.businessName"
                    control={businessControl}
                    defaultValue=""
                    rules={{
                      required: "Buiness name is required",
                      pattern: {
                        value: /^[A-Za-z]+$/i,
                        message:
                          "Buiness name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<Homework />}
                        label="Business Name"
                        placeholder="Enter business name"
                        error={
                          businessErrors.business?.businessName
                            ? businessErrors.business.businessName
                              .message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.address1"
                    control={businessControl}
                    defaultValue="xdue"
                    rules={{ required: "Address Line 1 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<AddressIcon />}
                        label="Address Line 1 (Street Address)"
                        placeholder="Enter address"
                        error={
                          businessErrors.business?.address1
                            ? businessErrors.business.address1.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.address2"
                    control={businessControl}
                    defaultValue="hdj"
                    rules={{ required: "Address Line 2 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Address Line 2 (Post office number, Suite Number, etc)"
                        placeholder="Enter post office number, suite number, etc."
                        error={
                          businessErrors.business?.address2
                            ? businessErrors.business.address2.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="business.state"
                    control={businessControl}
                    defaultValue={userInfo?.state || ''}
                    rules={{ required: "State is required" }}
                    render={({ field }) => {
                      const stateOptions = countries;
                      const selectedOption = stateOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth={true}
                          icon={<Homework />}
                          error={
                            businessErrors.business?.state
                              ? businessErrors.business.state.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="business.city"
                    control={businessControl}
                    defaultValue={userInfo?.city || ''}
                    rules={{ required: "City is required" }}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption || null}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                          error={
                            businessErrors.business?.city
                              ? businessErrors.business.city.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="business.postalCode"
                    control={businessControl}
                    defaultValue="52514"
                    rules={{
                      required: "Postal Code is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message:
                          "Only numbers are allowed for Postal Code",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<MyLocation />}
                        label="Postal Code"
                        placeholder="Enter postal code"
                        error={
                          businessErrors.business?.postalCode
                            ? businessErrors.business?.postalCode
                              .message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow> : <FormInputRow>
              <FormInputCol md={12}>
                <Controller
                  name="business.firstName"
                  control={businessControl}
                  defaultValue=""
                  rules={{
                    required: "First name is required",
                    pattern: {
                      value: /^[A-Za-z]+$/i,
                      message:
                        "First name can only contain alphabets",
                    },
                  }}
                  render={({ field }) => (
                    <InputComponent
                      {...field}
                      icon={<UserIconGray />}
                      label="First Name"
                      placeholder="Enter first name"
                      error={
                        businessErrors.business?.firstName
                          ? businessErrors.business.firstName
                            .message
                          : null
                      }
                    />
                  )}
                />
              </FormInputCol>
              <FormInputCol md={12}>
                <Controller
                  name="business.lastName"
                  control={businessControl}
                  defaultValue=""
                  rules={{
                    required: "Last name is required",
                    pattern: {
                      value: /^[A-Za-z ]+$/i,
                      message:
                        "Last name can only contain alphabets",
                    },
                  }}
                  render={({ field }) => (
                    <InputComponent
                      {...field}
                      icon={<UserIconGray />}
                      label="Last Name"
                      placeholder="Enter last name"
                      error={
                        businessErrors.business?.lastName
                          ? businessErrors.business.lastName.message
                          : null
                      }
                    />
                  )}
                />
              </FormInputCol>
              <FormInputCol lg={12} md={12} xl={12}>
                <FormLabel
                  component="legend"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#374151",
                  }}
                >
                  Select Phone Type
                </FormLabel>
                <Controller
                  name="business.selectedPhone"
                  control={businessControl}
                  defaultValue=""
                  rules={{
                    required: "Please select a phone type",
                  }}
                  render={({ field }) => (
                    <>
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="Home Phone"
                          control={<Radio />}
                          label="Home Phone"
                        />
                        <FormControlLabel
                          value="Cell Phone"
                          control={<Radio />}
                          label="Cell Phone"
                        />
                        <FormControlLabel
                          value="Business Phone"
                          control={<Radio />}
                          label="Business Phone"
                        />
                        <FormControlLabel
                          value="Evening Phone"
                          control={<Radio />}
                          label="Evening Phone"
                        />
                      </RadioGroup>
                      {businessErrors.business?.selectedPhone && (
                        <Typography
                          style={{
                            color: "#DF6060",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {
                            businessErrors.business?.selectedPhone
                              .message
                          }
                        </Typography>
                      )}
                    </>
                  )}
                />
              </FormInputCol>
              <FormInputCol lg={12} md={12} xl={12}>
                <Controller
                  name="business.phoneNumber"
                  control={businessControl}
                  defaultValue=""
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message:
                        "Only numbers are allowed for Phone Number",
                    },
                  }}
                  render={({ field }) => (
                    <InputComponent
                      {...field}
                      label="Phone Number"
                      icon={<PhoneIcon />}
                      placeholder="Enter phone number"
                      error={
                        businessErrors.business?.phoneNumber
                          ? businessErrors.business?.phoneNumber
                            .message
                          : null
                      }
                    />
                  )}
                />
              </FormInputCol>
              <FormInputCol lg={12} md={12} xl={12}>
                <Divider sx={{ border: "1px dashed #D5E1F9" }} />
              </FormInputCol>
              <FormInputCol lg={12} md={12} xl={12}>
                <Controller
                  name="business.email"
                  control={businessControl}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Enter a valid email address",
                    },
                  }}
                  render={({ field }) => (
                    <InputComponent
                      {...field}
                      label="Email Address"
                      icon={<EmailIcon />}
                      placeholder="Enter Email"
                      error={
                        businessErrors.business?.email
                          ? businessErrors.business?.email.message
                          : null
                      }
                    />
                  )}
                />
              </FormInputCol>
            </FormInputRow>}
          </Box>
        </Box>
      </EditModal>
    </Box>
  )
}

export default ProfileInformation;
