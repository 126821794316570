import React, { useState, MouseEvent } from "react";
import {
  Button,
  Popover,
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import CalenderIcon from "../../assets/IconComponents/CalenderIcon";

const monthsAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface CustomDatePickerProps {
  selectedDateProp?: {
    day: number | null;
    month: number | null;
    year: number | null;
  };
  onDateSelect?: (date: {
    day: number | null;
    month: number | null;
    year: number | null;
  }) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  popoverWidth?: string;
  popoverHeight?: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedDateProp = { day: null, month: null, year: null },
  onDateSelect = () => { },
  placeholder = "Select Date",
  style = {},
  popoverWidth = "400px",
  popoverHeight = "fit-content",
}) => {
  const currentYear = new Date().getFullYear();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = useState<{
    day: number | null;
    month: number | null;
    year: number | null;
  }>(selectedDateProp);
  const [yearRange, setYearRange] = useState<[number, number]>([
    currentYear - 5,
    currentYear + 5,
  ]);
  const [selectionStep, setSelectionStep] = useState<"day" | "month" | "year">(
    "day"
  );

  const theme = useTheme();

  const [tempDate, setTempDate] = useState<{
    day: number | null;
    month: number | null;
    year: number | null;
  }>(selectedDateProp);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDaySelect = (day: number) => {
    setTempDate({ ...tempDate, day });
    setSelectionStep("month");
  };

  const handleMonthSelect = (monthIndex: number) => {
    setTempDate({ ...tempDate, month: monthIndex });
    setSelectionStep("year");
  };

  const handleYearSelect = (year: number) => {
    setTempDate({ ...tempDate, year });
  };

  const handleNextRange = () => {
    setYearRange([yearRange[0] + 10, yearRange[1] + 10]);
  };

  const handlePreviousRange = () => {
    setYearRange([yearRange[0] - 10, yearRange[1] - 10]);
  };

  const handleOk = () => {
    setSelectedDate(tempDate);
    handleClose();
    if (onDateSelect) {
      onDateSelect(tempDate);
    }
  };

  const handleCancel = () => {
    setTempDate({ day: null, month: null, year: null });
    setSelectedDate({ day: null, month: null, year: null });
    setSelectionStep("day");
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-popover" : undefined;

  const daysInMonth =
    tempDate.month !== null
      ? new Date(currentYear, tempDate.month + 1, 0).getDate()
      : 31;

  const formattedDate =
    selectedDate.day !== null &&
      selectedDate.month !== null &&
      selectedDate.year !== null
      ? `${selectedDate.day.toString().padStart(2, "0")}/${(
        selectedDate.month + 1
      )
        .toString()
        .padStart(2, "0")}/${selectedDate.year}`
      : "";

  return (
    <div>
      <TextField
        placeholder={placeholder}
        fullWidth
        style={{
          fontSize: "16px",
          color: "#6B7280",
          background: "#F9FAFB",
          ...style,
        }}
        size="small"
        variant="outlined"
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                display: "flex",
                alignItems: "center",
                width: "18px",
                height: "18px",
              }}
            >
              <CalenderIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={formattedDate}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: popoverWidth,
            height: popoverHeight,
            gap: "10px",
            borderRadius: "8px 0 0 0",
            opacity: 1,
          },
        }}
      >
        <Box sx={{ marginTop: "10px", padding: "5px" }}>
          {selectionStep === "day" && (
            <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
              {Array.from({ length: daysInMonth }).map((_, day) => (
                <Grid item key={day + 1} p={"0"}>
                  <Button
                    variant={tempDate.day === day + 1 ? "contained" : "text"}
                    onClick={() => handleDaySelect(day + 1)}
                    sx={{
                      width: "36px",
                      height: "34px",
                      borderRadius: "1vw",
                      padding: tempDate.day === day + 1 ? "16px" : "0",
                      backgroundColor:
                        tempDate.day === day + 1 ? theme.palette.primary.main : "transparent",
                      color: tempDate.day === day + 1 ? "#fff" : "#374151",
                      fontSize: "12px",
                      fontWeight: "700",
                      lineHeight: "18px",
                    }}
                  >
                    {day + 1}
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}

          {selectionStep === "month" && (
            <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
              {monthsAbbreviated.map((month, index) => (
                <Grid item key={month} p={"0"}>
                  <Button
                    variant={tempDate.month === index ? "contained" : "text"}
                    onClick={() => handleMonthSelect(index)}
                    sx={{
                      width: "36px",
                      height: "34px",
                      borderRadius: "1vw",
                      padding: tempDate.month === index ? "16px" : "0",
                      backgroundColor:
                        tempDate.month === index ? theme.palette.primary.main : "transparent",
                      color: tempDate.month === index ? "#fff" : "#374151",
                      fontSize: "12px",
                      fontWeight: "700",
                      lineHeight: "18px",
                    }}
                  >
                    {month}
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}

          {selectionStep === "year" && (
            <>
              <Grid
                container
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  sx={{ width: "20px", height: "20px" }}
                  onClick={handlePreviousRange}
                >
                  {"<"}
                </Button>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "18px",
                  }}
                >
                  {yearRange[0]} - {yearRange[1]}
                </Typography>
                <Button
                  sx={{ width: "20px", height: "20px" }}
                  onClick={handleNextRange}
                >
                  {">"}
                </Button>
              </Grid>

              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                {Array.from({ length: yearRange[1] - yearRange[0] + 1 }).map(
                  (_, i) => {
                    const year = yearRange[0] + i;
                    return (
                      <Grid item key={year} p={"0"}>
                        <Button
                          variant={
                            tempDate.year === year ? "contained" : "text"
                          }
                          onClick={() => handleYearSelect(year)}
                          sx={{
                            width: "36px",
                            height: "34px",
                            borderRadius: "1vw",
                            padding: tempDate.year === year ? "16px" : "0",
                            backgroundColor:
                              tempDate.year === year
                                ? theme.palette.primary.main
                                : "transparent",
                            color: tempDate.year === year ? "#fff" : "#374151",
                            fontSize: "12px",
                            fontWeight: "700",
                            lineHeight: "18px",
                          }}
                        >
                          {year}
                        </Button>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </>
          )}
          <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"center"}>
            <Box display="flex" justifyContent="center" mt={2} width={"90%"}>
              <Button
                variant="contained"
                onClick={handleOk}
                sx={{
                  background: theme.palette.primary.main,
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "45%"
                }}

              >
                OK
              </Button>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{
                  marginRight: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "45%"
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default CustomDatePicker;
