import { Box, useMediaQuery } from "@mui/material";
import { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import PageLoader from "../../pages/misc/PageLoader";
import HeaderComponent from '../header';
import SidnavComponent from '../navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { AccountInstrument } from '../../api/accountsAPI';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const { app: appCtx, updateCtx } = useContext(AppContext);
  const [sideNavOpen, setSideNavOpen] = useState(true); // State to control sidenav visibility
  let isLargeScreen = useMediaQuery("(min-width:1024px)");

  // Hit the instrument API to fetch the user detail
  useEffect(() => {
    instrumentAPI();
    if (isLargeScreen) {
      setSideNavOpen(true);
    }
  }, []);

  /**
   * @description Add event listener to the window re-size side effects
   */
  useLayoutEffect(() => {
    function updateSize() {
      // isLargeScreen = useMediaQuery("(min-width:767px)")

      const width = window.innerWidth;

      if (width > 1023) {
        setSideNavOpen(true);
      } else {
        setSideNavOpen(false);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const instrumentAPI = async () => {
    try {
      const userInformation = await AccountInstrument();

      updateCtx({
        ...appCtx,
        user: {
          firstName: 'Demo',
          lastName: 'User'
        },
        isLoggedIn: true
      });

    } catch (err) {
      navigate('/auth/login');
    }
  };

  return (
    <Box sx={{
      // display: "flex",
      flexDirection: "column",
      height: '100dvh',
      overflow: "hidden",
      overflowY: "auto",
    }}>
      {appCtx.isLoggedIn ? (
        <Fragment>
          <HeaderComponent toggleSideNav={toggleSideNav} />
          <SidnavComponent toggleSideNav={toggleSideNav} showSideNavBar={sideNavOpen} isLargeScreen={isLargeScreen} />
          <Box
            component="main"
            sx={{
              width: isLargeScreen ? "calc(100% - 300px)" : "100%",
              marginLeft: isLargeScreen ? "300px" : "0",
              flexGrow: 1,
              p: { xs: 2, sm: "20px" },
              position: "relative",
              zIndex: 1,
            }}
          >
            <Outlet />
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <PageLoader />
        </Fragment>
      )}
    </Box>
  );
};

export default DashboardLayout;
