import React from "react";
import { Modal, Box, Grid2 as Grid, Typography, Button, Divider } from "@mui/material";

interface EditModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  handleUpdate?: () => void;
  children: React.ReactNode;
  modalStyle?: object;
  buttonStyle?: object;
  cancelText?: string;
  updateText?: string;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
}

const EditModal: React.FC<EditModalProps> = ({
  open,
  onClose,
  title,
  children,
  handleUpdate,
  modalStyle,
  buttonStyle,
  cancelText = "Cancel",
  updateText = "Update",
  headerContent,
  footerContent
}) => {
  const defaultModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 2,
    width: { xs: "90%", md: "90%", lg: 1200 },
    maxHeight: "90vh",
    overflowY: "auto",
    ...modalStyle,
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={defaultModalStyle}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ padding: "20px" }}>
          {headerContent ? headerContent : (
            <Typography fontSize={20} fontWeight={600}>
              {title}
            </Typography>
          )}
          <Grid>
            <Button
              size="large"
              onClick={onClose}
              sx={{
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: "600",
                marginRight: "20px",
                border: "1px solid #001F3D",
                color: "#001F3D",
                ...buttonStyle,
              }}
            >
              {cancelText}
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={handleUpdate}
              sx={{
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: "600",
                backgroundColor: "#1F3A91",
                color: "white",
                "&:hover": { backgroundColor: "#1F3A91" },
                ...buttonStyle,
              }}
            >
              {updateText}
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ padding: "20px" }}>
          {children}
        </Box>
        {footerContent && <Divider />}
        {footerContent && <Box sx={{ padding: "20px" }}>{footerContent}</Box>}
      </Box>
    </Modal>
  );
};

export default EditModal;
