
import * as Yup from 'yup';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    full_name: Yup.string().required('Full Name is required'),
    phone_no: Yup.string().required('Phone number is required'),
    company_name: Yup.string().required('Company name is required'),
    contact_sales: Yup.boolean(),
    role: Yup.string(),
    query: Yup.string(),
});

export default validationSchema;