import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AppContext } from '../context/AppContext';
import { Fragment } from 'react/jsx-runtime';
import PageLoader from '../pages/misc/PageLoader';
import { useContext, useEffect, useState } from 'react';
import { AccountInstrument } from '../api/accountsAPI';

const AuthLayout = (props: any) => {

    const navigate = useNavigate();

    const { app: appCtx, updateCtx } = useContext(AppContext);

    const [isLoading, setLoading] = useState(false);

    // Hit the instrument API to fetch the user detail
    useEffect(() => {
        instrumentAPI();
    }, []);

    const instrumentAPI = async () => {
        try {
            setLoading(true);
            const userInformation = await AccountInstrument();
            updateCtx({
                ...appCtx,
                user: {
                    firstName: 'Demo',
                    lastName: 'User'
                },
                isLoggedIn: true
            });
            setLoading(false);
            // Go to dashboard page
            navigate('/');

        } catch (err) {
            updateCtx({
                ...appCtx,
                user: {
                    firstName: '',
                    lastName: ''
                },
                isLoggedIn: false
            });
            setLoading(false);
            // navigate('/auth/login');
        }
    };

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: '100vh',
        overflow: "hidden",
        overflowY: "scroll",
        width: '100%'
    }}>
        {!isLoading ? <Fragment>
            <Outlet />
        </Fragment> :
            <Fragment>
                <PageLoader></PageLoader>
            </Fragment>}
    </Box>
};

export default AuthLayout;
