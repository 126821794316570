import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as BankSureLogo } from '../../assets/media/svg/banksure-logo-2.svg';
import { Box, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import menuItems from '../../menu-items';
import './SideNavBar.css';
import { Logout } from '@mui/icons-material';
import { LogOut } from '../../api/AuthAPI';

const openedMixin = (theme: any) => ({
    width: 300,
    borderRight: '1px solid',
    backgroundColor: theme.primary,
    borderRightColor: theme?.palette?.divider,
    transition: theme?.transitions.create('width', {
        easing: theme?.transitions?.easing?.sharp,
        duration: theme?.transitions?.duration?.enteringScreen,
    }),
    overflowX: 'hidden',
    boxShadow: 'none',
});

const closedMixin = (theme: any) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    borderRight: 'none',
});

const DrawerComponent = styled(Drawer)(({ theme, open, variant }: any) => ({
    ...(variant === 'temporary' ? {} : {
        width: 300,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        zIndex: 1200,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme),
                zIndex: 1200,
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    height: 70,
    marginTop: 20,
    marginLeft: 20,
}));

const SidnavComponent = ({ showSideNavBar, toggleSideNav, isLargeScreen }: any) => {
    const navigate = useNavigate();
    const onLogOut = async () => {
        try {
          const response = await LogOut();
          navigate("/auth/login");
        } catch (err) {
          console.error("Error in logout");
        }
      };
    return (
        <DrawerComponent
            variant={isLargeScreen ? 'permanent' : 'temporary'}
            open={showSideNavBar}
            onClose={() => toggleSideNav()}
        >
            <IconWrapper>
                <BankSureLogo />
            </IconWrapper>
            <MenuList sx={{ color: 'white' }}>
                {menuItems.map((item: any, index: number) => (
                    <MenuItem key={item.label} disableRipple>
                        <NavLink
                            to={item.to}
                            className={(navData) => (navData.isActive ? 'route-active sidebar-navlink' : 'sidebar-navlink')}
                            style={{ width: '90%', textDecoration: 'none !important' }}
                            onClick={() => !isLargeScreen && toggleSideNav()}
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <item.icon className="nav-menu-icons" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="subtitle2" sx={{ color: 'white' }}>
                                        {item.label}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                    </MenuItem>
                ))}
                {!isLargeScreen && (
                    <MenuItem disableRipple>
                        <NavLink to="#" onClick={onLogOut} className="sidebar-navlink">
                            <ListItem>
                                <ListItemIcon>
                                    <Logout className="nav-menu-icons" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="subtitle2" sx={{ color: 'white' }}>
                                        Logout
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </NavLink>
                    </MenuItem>
                )}
            </MenuList>
        </DrawerComponent>
    );
};

export default SidnavComponent;
