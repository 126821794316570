const DownArrow = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91 8.29492L12.5 12.8749L17.09 8.29492L18.5 9.70492L12.5 15.7049L6.5 9.70492L7.91 8.29492Z"
        fill="white"
      />
    </svg>
  );
};

export default DownArrow;
