import {
  Grid2 as Grid,
  Chip,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  TableContainer,
} from "@mui/material";
import Panel from "../../../components/Panel";
import "./IbaReport.css";
import { useEffect, useState } from "react";
import { GetTransactionHistory } from "../../../api/IBAReport";
import moment from "moment";
import { TableSkeletonComponent } from "../../../components/form/TableComponent";
import IncomeIcon from "../../../assets/IconComponents/IncomeIcon";
import TransferIcon from "../../../assets/IconComponents/TransferIcon";

const IconMap: any = {
  Income: <IncomeIcon />,
  Reimbursement: <IncomeIcon />,
  Transfer: <TransferIcon />,
  Miscellaneous: <IncomeIcon />,
};

export const valueFormatter = (item: { value: number }) => `${item.value}%`;

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid #D5E1F9 !important`,
  fontSize: "14px",
  color: "#616773",
  fontWeight: "600",
}));

const TransactionHistoryComponent = (props: any) => {
  const theme = useTheme();

  const [transactionsList, setTransactionsList] = useState<any[]>([]);
  const [isLoading, toggleLoading] = useState<Boolean>(false);

  const [query, setQuery] = useState({
    acctId: "6200566",
    acctType: "SDA",
    extAcctType: "SDA",
    userID: "User1",
    cryptVal: "cashedge1",
    StartDt: "2021-01-01",
    EndDt: "2021-09-30",
  });

  const dummyData = [
    {
      createdOnDt: "2021-09-01",
      category: "Income",
      memo: "Sample Transaction",
      curAmt: { amt: "3,482.42" },
    },
    {
      createdOnDt: "2021-09-02",
      category: "Transfer",
      memo: "Sample Transaction 2",
      curAmt: { amt: "3,482.42" },
    },
  ];

  useEffect(() => {
    getData(query);
  }, []);

  const getData: any = async (query: object) => {
    toggleLoading(true);
    try {
      const result = await GetTransactionHistory(query);

      setTransactionsList(
        result?.depAcctTrnInqRs?.depAcctTrns?.bankAcctTrnRec || []
      );
    } catch (err) {
      console.error("Error : ", err);
    }
    toggleLoading(false);
  };

  return (
    <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
      <Panel
        sx={{
          backgroundColor: theme?.palette?.primary?.main,
          padding: 2,
          color: "white",
          borderRadius: "0px",
          borderBottom: "3px solid #D6C64D",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
          Transaction History
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          Wells fargo {query.acctId}
        </Typography>
      </Panel>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>Date</CustomTableCell>
              <CustomTableCell>Category</CustomTableCell>
              <CustomTableCell>Description/Memo</CustomTableCell>
              <CustomTableCell>Deposits ($)</CustomTableCell>
              <CustomTableCell>Withdrawls ($)</CustomTableCell>
              <CustomTableCell sx={{ textAlign: "right" }}>
                Balance ($)
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableSkeletonComponent rows={1} columns={6} />
            ) : (
              transactionsList.map((transaction: any, index: number) => (
                <TableRow key={`table_row_${index}`}>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {moment(transaction.createdOnDt).format("MM/DD/YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <Chip
                        icon={IconMap[transaction.category]}
                        label={transaction.category}
                        sx={{
                          backgroundColor: "#F0F8FE",
                          color: "#1D3DB0",
                          width: "180px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {transaction.memo}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {transaction?.curAmt?.amt
                        ? `$ ${transaction.curAmt.amt}`
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      -
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        textAlign: "right",
                        color: "#333333",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      $ {transaction?.curAmt?.amt || 0}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TransactionHistoryComponent;
