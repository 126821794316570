import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';


const TopTenCustomerChart = () => {
    const data = {
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        datasets: [
            {
                label: 'Expired Requests',
                data: [30, 20, 25, 40, 35, 20, 30],
                backgroundColor: '#3653D9',
                hoverBackgroundColor: '#3653D9',
                // borderRadius: { topLeft: 6, topRight: 6, bottomLeft: 0, bottomRight: 0 },
                borderSkipped: false,
            },
            {
                label: 'Completed Requests',
                data: [80, 100, 90, 120, 140, 110, 100],
                backgroundColor: '#8C9CE8',
                hoverBackgroundColor: '#8C9CE8',
                // borderRadius: { topLeft: 6, topRight: 6, bottomLeft: 0, bottomRight: 0 },
                borderSkipped: false,
            },
            {
                label: 'Total Requests',
                data: [110, 120, 115, 160, 175, 130, 140],
                backgroundColor: '#F8F7FC',
                hoverBackgroundColor: '#F8F7FC',
                // borderRadius: { topLeft: 6, topRight: 6, bottomLeft: 0, bottomRight: 0 },
                borderSkipped: false,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.raw;
                        return label;
                    },
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                max: 190,
                grid: {
                    display: false,
                },
            },
        },
    };

    return <PieChart
        series={[
            {
                data: [
                    { label: 'Individual', value: 60 },
                    { label: 'Business', value: 40 },
                    { label: 'All', value: 20 },],
                cx: '60%',
                cy: 200,
                innerRadius: 40,
                outerRadius: 80,
                startAngle: -90,
                endAngle: 90,
            },
        ]}
        height={300}
        slotProps={{
            legend: { hidden: true },
        }}
    />
};

export default TopTenCustomerChart;
