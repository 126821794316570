import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomMonthPicker from "../../components/form/MonthPicker";
import YearPicker from "../../components/form/YearPicker";
import { useState } from "react";
import PaidIcon from "../../assets/IconComponents/PaidIcon";
import AvatarIcon from "../../assets/IconComponents/AvatarIcon";

const InvoiceReport = () => {
  const [InvoiceRecordList, setInvoiceRecordList] = useState([
    {
      product: "IBA",
      totalReports: 180,
      dueDate: "15-06-2023",
      PaymentDate: "15-06-2023",
      status: "Paid",
      totalPrice:"$225.75"
    },
  ]);
  return (
    <Box sx={{ padding: 1 }}>
      <Grid container size={12} rowSpacing={2} spacing={2}>
        <Grid size={12} container spacing={2}>
          <Grid size={8}>
            <Typography variant="h5" fontWeight={700}>
              #605
            </Typography>
            <Typography>Invoice Report</Typography>
          </Grid>
          <Grid
            container
            size={4}
            spacing={2}
            display={"flex"}
            justifyContent={"space-around"}
          >
            <Grid size={6}>
              <Button
                sx={{
                  backgroundColor: "#fff",
                  color: "#1F2A37",
                  border: "1px solid #001F3D",
                }}
                fullWidth
              >
                Download Invoice
              </Button>
            </Grid>
            <Grid size={6}>
              <Button
                sx={{ backgroundColor: "#274d8e", color: "white" }}
                fullWidth
              >
                Resend Invoice
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Card sx={{ width: "100%" }}>
          <CardContent sx={{ padding: "20px 20px" }}>
            <Grid container spacing={2} display={"flex"} alignItems={"center"}>
              <Grid size={6}>
                <Typography variant="h5" fontWeight={"500"}>
                  Invoice Details
                </Typography>
              </Grid>
              <Grid
                size={6}
                display={"flex"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <Grid size={4} sx={{ marginRight: 2 }}>
                  <CustomMonthPicker />
                </Grid>
                <Grid size={4}>
                  <YearPicker />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent sx={{ padding: "20px 20px" }}>
            <Grid container spacing={2}>
              
              <Grid size={8} display={"flex"}>
              <Grid size={1.3}>
                <AvatarIcon />
              </Grid>
               <Grid size={6.7}>
               <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontWeight: "400",
                    color: "#374151",
                  }}
                >
                  Invoice ID:
                  <Typography
                    component="span"
                    sx={{ fontSize: "16px", fontWeight: "500", color: "black" }}
                  >
                    &nbsp;#605
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontWeight: "400",
                    color: "#374151",
                  }}
                >
                  Client Name:
                  <Typography
                    component="span"
                    sx={{ fontSize: "16px", fontWeight: "500", color: "black" }}
                  >
                    &nbsp;ABC Lending
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontWeight: "400",
                    color: "#374151",
                  }}
                >
                  Email ID:
                  <Typography
                    component="span"
                    sx={{ fontSize: "16px", fontWeight: "500", color: "black" }}
                  >
                    &nbsp;abclending@gmail.com
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontWeight: "400",
                    color: "#374151",
                  }}
                >
                  Mobile No.:
                  <Typography
                    component="span"
                    sx={{ fontSize: "16px", fontWeight: "500", color: "black" }}
                  >
                    &nbsp;(808) 762 9622
                  </Typography>
                </Typography>
               </Grid>
               
              </Grid>
              <Grid
                size={4}
                display="flex"
                flexDirection={"column"}
                justifyContent="flex-start"
                alignItems={"end"}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontWeight: "400",
                    color: "#374151",
                  }}
                >
                  Invoice ID:
                  <Typography
                    component="span"
                    sx={{ fontSize: "16px", fontWeight: "500", color: "black" }}
                  >
                   &nbsp;abclending000123
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    fontWeight: "400",
                    color: "#374151",
                  }}
                >
                  Invoice Date:
                  <Typography
                    component="span"
                    sx={{ fontSize: "16px", fontWeight: "500", color: "black" }}
                  >
                    &nbsp;07-06-2023
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid size={12} rowSpacing={2} rowGap={5} marginTop={2}>
              <Table>
                <TableHead sx={{ backgroundColor: "#eef7ff" }}>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Total Reports</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Total Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {InvoiceRecordList.map((element, index: number) => (
                    <TableRow key={`table_row_${index}`}>
                      <TableCell>
                        <Typography>{element.product}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{element.totalReports}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{element.dueDate}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{element.PaymentDate}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography><PaidIcon /></Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={600}>{element.totalPrice}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default InvoiceReport;
