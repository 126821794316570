import { useState } from 'react';
import Palette from './theme2';
import ThemeV1 from './theme1';
import ThemeV2 from './theme2';
import { ThemeProvider } from '@emotion/react';
import { Theme } from '@mui/material/styles';

// Have a Theme selection option for future purposes
export const ThemeMap: { [key: string]: Theme } = {
    'v1': ThemeV1,
    'v2': ThemeV2,
};

export default function CustomThemeProvider({ children }: any) {
    const [currentTheme, setTheme] = useState('v1');

    return <ThemeProvider theme={ThemeMap[currentTheme] || ThemeMap[1]}>
        {children}
    </ThemeProvider>
}