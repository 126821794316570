import React from "react";
import { Box, Typography } from "@mui/material";
import {
  CartesianGrid,
  LineChart,
  Line,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";

interface LineChartProps {
  labels?: string[];
  dataPoints?: number[];
  threshold?: number;
  title?: string;
  subTitle?: string;
  XLabel?: string;
}

const CustomizedDot = (props: any) => {
  const { cx, cy, value } = props;
  const dotSize = 5; 
  const dotColor = value > 350 ? "#FF0000" : "#1D4F9E";
  return <circle cx={cx} cy={cy} r={dotSize} fill={dotColor} />;
};

const LineCharts: React.FC<LineChartProps> = ({
  labels,
  dataPoints,
  threshold,
  title,
  subTitle,
  XLabel = "Monthly Installment",
}) => {
  const lineData = [
    { frequency: "", amount: 0 },
    { frequency: "", amount: 200 },
    { frequency: "", amount: 400 },
    { frequency: "", amount: 600 },
    { frequency: "", amount: 780 },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      {title && (
        <Box
          sx={{
            background: "#F0F8FE",
            width: "fit-content",
            padding: "10px",
            marginBottom: 2,
            borderRadius: "8px",
          }}
        >
          <Typography
            component={"span"}
            sx={{ fontSize: "14px", fontWeight: "700", color: "#1A2D51" }}
          >
            {title}{" "}
          </Typography>
          <Typography
            component={"span"}
            sx={{ fontSize: "14px", fontWeight: "800", color: "#2553A8" }}
          >
            {subTitle}
          </Typography>
        </Box>
      )}
      <LineChart width={300} height={200} data={lineData}>
        <CartesianGrid strokeWidth={2} />
        <XAxis
          dataKey="frequency"
          tickSize={0}
          tickMargin={20}
          strokeWidth={2}
          axisLine={false}
        />
        <YAxis
          tickSize={0}
          tickFormatter={(value) => `$${value}`}
          tickMargin={20}
          strokeWidth={2}
          axisLine={false}
        />
        <ReferenceLine y={350} stroke="#FF0000" strokeDasharray="3 3" />
        <Line
          dataKey="amount"
          stroke="#1D4F9E"
          dot={<CustomizedDot />}
          strokeWidth={2}
        />
      </LineChart>
      <Box sx={{ display: "flex", justifyContent: "center"}}>
        <Typography
          component={"span"}
          sx={{ fontSize: "16px", fontWeight: "400", color: "#000000" }}
        >
          {XLabel}
        </Typography>
      </Box>
    </Box>
  );
};

export default LineCharts;
