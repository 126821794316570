import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Severity = "success" | "info" | "warning" | "error";

interface ToastState {
  open: boolean;
  message: string;
  severity: Severity;
}

interface ToastContextType {
  showToast: (message: string, severity?: Severity) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<ToastState>({
    open: false,
    message: "",
    severity: "success",
  });

  const showToast = useCallback((message: string, severity: Severity = "success") => {
    setToast({ open: true, message, severity });
  }, []);

  const closeToast = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setToast((prevState) => ({ ...prevState, open: false }));
  }, []);

  const alertStyles = {
    info: { border: "1px solid #1976d2" },
    warning: { border: "1px solid #ffa726" },
    success: { border: "1px solid #66bb6a" },
    error: { border: "1px solid #f44336" },
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.open}
        sx={{ width: "627.16px", height: "56px", gap: "10px" }}
        autoHideDuration={4000}
        onClose={closeToast}
      >
        <Alert
          onClose={closeToast}
          severity={toast.severity}
          sx={{ width: "100%", ...alertStyles[toast.severity] }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeToast}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <span
            style={{
              fontSize: "18px",
              color: "#374151",
              fontWeight: "600",
              marginLeft: "15px",
            }}
          >
            {toast.message}
          </span>
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
