import React from 'react'

const ExpandMoreIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.59 8.295L12 12.875L7.41 8.295L6 9.705L12 15.705L18 9.705L16.59 8.295Z" fill="#1D3DB0"/>
    </svg>
    
  )
}

export default ExpandMoreIcon