const AvatarIcon = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="54" rx="27" fill="#D9D9D9"/>
    <path d="M32.4264 20.8528C32.4264 23.7744 30.0019 26.1518 26.9998 26.1518C23.9977 26.1518 21.5732 23.7744 21.5732 20.8528C21.5732 17.9313 23.9977 15.5539 26.9998 15.5539C30.0019 15.5539 32.4264 17.9313 32.4264 20.8528ZM20.8315 29.5845C21.9664 28.4752 23.5062 27.8503 25.1134 27.8484H28.8863C30.4935 27.8503 32.0333 28.4752 33.1682 29.5845C34.3029 30.6936 34.9402 32.1962 34.9421 33.7624V37.4504C34.9421 37.7128 34.8355 37.9656 34.644 38.1527C34.4523 38.3401 34.1912 38.4463 33.9179 38.4463H20.0818C19.8085 38.4463 19.5474 38.3401 19.3557 38.1527C19.1642 37.9656 19.0576 37.7128 19.0576 37.4504V33.7622C19.0595 32.1961 19.6968 30.6936 20.8315 29.5845Z" fill="#FAFAFA" stroke="#FAFAFA" stroke-width="0.467192"/>
    </svg>
    
  )
}

export default AvatarIcon