import { Box, Grid2 as Grid, Typography, useTheme} from "@mui/material"
import Panel from "../../../components/Panel"
import BarChart from "../../../components/charts/BarChart"
import LineCharts from "../../../components/charts/LineCharts";

const LoanInstalmentAmount = () => {
    const theme = useTheme();

    const labels = ["$750", "$550", "$350", "$0"];
  const dataPoints = [0, 350, 550, 750];
  const threshold = 550;

  const dtiData = {
    labels: ["100%", "90%", "80%", "70%", "60%", "50%", "40%", "30%", "20%", "10%", "0%"],
    datasets: [
      {
        label: "DTI Score",
        data: [500, 450, 400, 350, 300, 250, 200, 150, 100, 50, 0],
        backgroundColor: [], 
      },
    ],
  };
    
  return (
    <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
    <Panel
      sx={{
        backgroundColor: theme?.palette?.primary?.main,
        padding: "3px",
        borderRadius: 0,
        borderBottom: "3px solid #D6C64D",
      }}
    ></Panel>
    <Box sx={{ border: "1.78px solid #F1F1F1", padding: "20px",}}>
      <Typography sx={{fontSize:"16px",fontWeight:'700'}}>Loan Instalment Amount Potential</Typography>
      <Grid container size={12}  sx={{ marginTop: 2 }} spacing={2}>
       <Grid container size={12}>
       <Grid size={3}>
       <LineCharts
              labels={labels}
              dataPoints={dataPoints}
              threshold={threshold}
              title={"Monthly Frequency:"}
              XLabel="Monthly Installment"
              subTitle=" 30 - 40"
            />
       </Grid>
       <Grid size={3}>
       <LineCharts
              labels={labels}
              dataPoints={dataPoints}
              threshold={threshold}
              title={"Bi-Monthly Frequency :"}
              XLabel="Bi-Monthly Installment"
              subTitle=" 39 - 54"
            />
       </Grid>
       <Grid size={3}>
       <LineCharts
              labels={labels}
              dataPoints={dataPoints}
              threshold={threshold}
              title={"Weekly Frequency :"}
              XLabel="Weekly Installment"
              subTitle=" 15 - 22"
            />
       </Grid>
       <Grid size={3}>
       <LineCharts
              labels={labels}
              dataPoints={dataPoints}
              threshold={threshold}
              title={"Bi-Weekly Frequency :"}
              XLabel="Bi-Weekly Installment"
              subTitle=" 20 - 30"
            />
       </Grid>
       </Grid>
       <Grid size={12} >
        <Box sx={{width:"100%"}}>
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <BarChart
            data={dtiData}
            title="DTI Score Threshold:"
            subTitle=" 80%"
            threshold={450} 
            axis="y"      
          />

        </Box>
        </Box>
     
      
       </Grid>
      </Grid>
     
    </Box>
  </Grid>
  )
}

export default LoanInstalmentAmount