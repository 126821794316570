const PhoneIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.16311 8.63811L4.90639 8.89484L5.07104 9.21842C6.31941 11.6718 8.32952 13.6726 10.7803 14.9283L11.1044 15.0944L11.3619 14.8369L13.1952 13.0036C13.2857 12.9131 13.419 12.8851 13.5295 12.923L13.5295 12.923L13.5348 12.9248C14.5186 13.2498 15.5799 13.425 16.6667 13.425C16.8489 13.425 17 13.5761 17 13.7583V16.6667C17 16.8489 16.8489 17 16.6667 17C9.11781 17 3 10.8822 3 3.33333C3 3.15114 3.15114 3 3.33333 3H6.25C6.43219 3 6.58333 3.15114 6.58333 3.33333C6.58333 4.42707 6.75813 5.47856 7.08218 6.46096C7.11923 6.58201 7.09159 6.70964 6.99645 6.80478L5.16311 8.63811Z"
        fill="#6B7280"
        stroke="#6B7280"
      />
    </svg>
  );
};

export default PhoneIcon;
